import { Chart as ChartJS, registerables } from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import DividerComponent from "../../../Divider/Divider";
import ProviderStepper from "../../../Stepper/ProviderStepper";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
ChartJS.register(...registerables);

const mockData = {
  labels: ["Dog", "Cat", "Bird", "Fish", "Turtle"],
  datasets: [
    {
      label: "Veri Seti 1",
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(0,0,0,1)",
      borderWidth: 1,
      data: [65, 59, 80, 81, 56],
    },
  ],
};

const Graphs = () => {
  const { t } = useTranslation();
  const [totalServices, setTotalServices] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [totalComplatedServices, setTotalComplatedServices] = useState(null);
  const [completedServicesDetail, setCompletedServicesDetail] = useState(null);
  const city = JSON.parse(localStorage.getItem("pet_user")).city;
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const API_URL =
    process.env.REACT_APP_NODE_ENV === "production"
      ? process.env.REACT_APP_PRODUCTION_API_URL
      : process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    const getValues = async () => {
      try {
        // Paralel API istekleri
        const [
          totalServicesRes,
          averageRatingRes,
          totalComplatedServicesRes,
          completedServicesDetailRes,
        ] = await Promise.all([
          axios.get(`${API_URL}service/dashboard/total_services`, {
            headers: { "petapp-auth-token": token },
          }),
          axios.get(`${API_URL}service/dashboard/average_rating`, {
            headers: { "petapp-auth-token": token },
          }),
          axios.get(`${API_URL}service/dashboard/completed_services`, {
            headers: { "petapp-auth-token": token },
          }),
          axios.get(`${API_URL}service/dashboard/completed_services_detail`, {
            headers: { "petapp-auth-token": token },
          }),
        ]);

        // Gelen verileri state'e kaydetme
        setTotalServices(totalServicesRes.data);
        setAverageRating(averageRatingRes.data);
        setTotalComplatedServices(totalComplatedServicesRes.data);
        setCompletedServicesDetail(
          completedServicesDetailRes.data.completed_services_detail
        );
      } catch (error) {
        console.log(error);
      }
    };
    getValues();
  }, []);

  const chartData = completedServicesDetail
    ? {
        labels: Object.keys(completedServicesDetail),
        datasets: [
          {
            label: t("services"),
            backgroundColor: "rgba(75,192,192,1)",
            borderColor: "rgba(0,0,0,1)",
            borderWidth: 1,
            data: Object.values(completedServicesDetail),
          },
        ],
      }
    : null;

  return (
    <div>
      {city ? (
        <div style={{ backgroundImage: "url('img/bg/counter_bg.jpg')" }}>
          <div className="d-flex flex-wrap flex-row justify-content-center align-items-center">
            <div className="col-lg-2 col-md-4 col-sm-6 mt-5">
                <div className="counter-item">
                  <h2 className="count">
                    <span className="odometer">
                      {totalComplatedServices ? totalComplatedServices.total_services : 0}
                    </span>
                  </h2>
                  <p style={{ fontSize: 12 }}>
                    {t("complated_total_services")}
                  </p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mt-5">
                <Link to={"/app/pro_pending_services"}>
                  <div className="counter-item">
                    <h2 className="count">
                      <span className="odometer">
                        {totalServices ? totalServices.total_services : 0}
                      </span>
                    </h2>
                    <p style={{ fontSize: 12 }}>
                      {t("waiting_total_services")}
                    </p>
                  </div>
                </Link>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mt-5">
                <div className="counter-item">
                  <h2 className="count">
                    <span className="odometer">
                    {averageRating ? averageRating.average_rating : 0}
                    </span>
                  </h2>
                  <p style={{ fontSize: 12 }}>
                    {t("customer_satisfaction_score")}
                  </p>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 mt-5">
              <div className="counter-item">
                <h2 className="count">
                  <span className="odometer">0</span>₺
                </h2>
                <p style={{ fontSize: 12 }}>{t("total_money")}</p>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-wrap justify-content-center align-items-center w-100"
            style={{ gap: 10 }}
          >
            <div
              style={{ width: "80%" }}
              className="shadow-lg bg-white rounded my-5"
            >
              <div className="d-flex justify-content-start align-items-start flex-column py-3">
                <h4 style={{ fontWeight: "600" }} className="mb-3 ml-5">
                  {t("services")}
                </h4>
                <DividerComponent color={"lightgray"} />
              </div>
              <div className="px-3 pb-3">
                {chartData && (
                  <Bar
                    data={chartData}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          ticks: {
                            stepSize: 1,
                            precision: 0,
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: true,
                          position: "right",
                        },
                      },
                    }}
                  />
                )}
              </div>
            </div>
            {/* <div
              style={{ width: "40%", height: "400px" }}
              className="shadow-lg bg-white rounded my-2">
              <div className="d-flex justify-content-start align-items-start flex-column py-3">
                <h4 style={{ fontWeight: "600" }} className="mb-3 ml-5">
                  {t("total_price_month")}
                </h4>
                <DividerComponent color={"lightgray"} />
              </div>
              <div className="px-3 pb-3">
                <Line
                  data={mockData}
                  options={{
                    scales: {
                      xAxis: {
                        type: "category",
                        labels: mockData.labels,
                      },
                      yAxis: {
                        beginAtZero: true,
                      },
                    },
                    plugins: {
                      legend: {
                        display: true,
                        position: "right",
                      },
                    },
                  }}
                />
              </div>
            </div>
            <div
              style={{ width: "40%", height: "400px" }}
              className="shadow-lg bg-white rounded my-2"
            >
              <div className="d-flex justify-content-start align-items-start flex-column py-3">
                <h4 style={{ fontWeight: "600" }} className="mb-3 ml-5">
                  {t("pet_breeds")}
                </h4>
                <DividerComponent color={"lightgray"} />
              </div>
              <div
                className="px-3 pb-3"
                style={{ width: "60%", marginLeft: "50px" }}
              >
                <Pie
                  data={mockData}
                  options={{
                    plugins: {
                      legend: {
                        display: true,
                        position: "right",
                      },
                    },
                  }}
                />
              </div>
            </div> */}
          </div>
        </div>
      ) : (
        <ProviderStepper />
      )}
    </div>
  );
};

export default Graphs;
