import React from "react";
import BreadCumb from "../../../Breadcrumb";
import { useTranslation } from "react-i18next";

const ActiveDetail = () => {
  const { t } = useTranslation();
  return (
    <div>
      <BreadCumb path={t("Active_Service_Detail")} />
      <section className="shop-details-area pt-110 pb-50">
        <div className="container">
          <div className="shop-details-wrap">
            <div className="row">
              <div className="col-7">
                <div className="shop-details-img-wrap">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane show active"
                      id="item-one"
                      role="tabpanel"
                      aria-labelledby="item-one-tab"
                    >
                      <div className="shop-details-img">
                        <img src="/img/product/shop_details01.jpg" alt="" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="item-two"
                      role="tabpanel"
                      aria-labelledby="item-two-tab"
                    >
                      <div className="shop-details-img">
                        <img src="/img/product/shop_details02.jpg" alt="" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="item-three"
                      role="tabpanel"
                      aria-labelledby="item-three-tab"
                    >
                      <div className="shop-details-img">
                        <img src="/img/product/shop_details03.jpg" alt="" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="item-four"
                      role="tabpanel"
                      aria-labelledby="item-four-tab"
                    >
                      <div className="shop-details-img">
                        <img src="/img/product/shop_details04.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="shop-details-content">
                  <span>{t("walker")}</span>
                  <h2 className="title">Jack</h2>
                  <div className="shop-details-price">
                    <h2 className="price">52.00₺</h2>
                    {/* <h5 className="stock-status">- IN Stock</h5> */}
                  </div>
                  <p>
                  {t("historydetail_p")}
                  </p>
                  <div className="shop-details-bottom">
                    <ul>
                      <li className="sd-category">
                        <span className="title">{t("service_provider")} :</span>
                        <span
                          style={{
                            color: "gray",
                            fontSize: 14,
                            fontWeight: "normal",
                          }}
                        >
                          Erdal Akçay
                        </span>
                      </li>
                      <li className="sd-category">
                        <span className="title">{t("agreement_date")} :</span>
                        <span
                          style={{
                            color: "gray",
                            fontSize: 14,
                            fontWeight: "normal",
                          }}
                        >
                          01/01/2024 12:00
                        </span>
                      </li>
                      <li className="sd-category">
                        <span className="title">{t("allergy")} :</span>
                        <span
                          style={{
                            color: "gray",
                            fontSize: 14,
                            fontWeight: "normal",
                          }}
                        >
                         {t("Cat_foods_dirty_water_etc")}
                        </span>
                      </li>
                      <li className="sd-category">
                        <span className="title">{t("special_cases")} :</span>
                        <span
                          style={{
                            color: "gray",
                            fontSize: 14,
                            fontWeight: "normal",
                          }}
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </span>
                      </li>
                      <li className="sd-category">
                        <span className="title">{t("available_dates")} :</span>
                        <div
                          style={{
                            color: "gray",
                            fontSize: 14,
                            fontWeight: "normal",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span>{t("monday")}: 14:00-18:00</span>
                          <span>{t("friday")}: 08:00-11:00</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ActiveDetail;
