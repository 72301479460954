import React from 'react';
import { useTranslation } from "react-i18next";

function Catagories() {
  const { t, i18n } = useTranslation();
  
  return (
	  <div className="widget">
                  <h4 className="sidebar-title">{t("categories")}</h4>
                  <div className="sidebar-cat-list">
                    <ul>
                      <li><a href="/#">{t("siberian_husky")} <i className="fas fa-angle-double-right" /></a></li>
                      <li><a href="/#">{t("german_sherped")} <i className="fas fa-angle-double-right" /></a></li>
                      <li><a href="/#">{t("french_bulldog")} <i className="fas fa-angle-double-right" /></a></li>
                      <li><a href="/#">{t("golden_retriever")} <i className="fas fa-angle-double-right" /></a></li>
                    </ul>
                  </div>
                </div>
  )
}

export default Catagories;
