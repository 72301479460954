import axios from "axios";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

const UserGeneralInfo = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("pet_user"));
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [phone, setPhone] = useState(user?.phone);
  const [birthday, setBirthday] = useState(user?.birthday);
  const [selectedCity, setSelectedCity] = useState(user?.city || null);
  const [selectedDistrict, setSelectedDistrict] = useState(
    user?.district || null
  );
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getCities = async () => {
      const response = await axios.get(`${API_URL}locations/cities`);

      setCities(response.data);
    };
    getCities();
  }, []);

  useEffect(() => {
    if (selectedCity) {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const getDistricts = async () => {
        const response = await axios.get(
          `${API_URL}locations/districts/${selectedCity}`
        );
        setDistricts(response.data);
      };
      getDistricts();
    }
  }, [selectedCity]);

  const handlePersonalInfo = async (e) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        fullname: e.currentTarget.fullname.value,
        email: e.currentTarget.email.value,
        phone: phone,
        birthday: birthday,
        city: e.currentTarget.city.value,
        district: e.currentTarget.district.value,
      };

      const res = await axios.put(`${API_URL}users/edit_user`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (res.data) {
        localStorage.setItem("pet_user", JSON.stringify(await res.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your informations has been successfully saved",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0]; // Seçilen dosyayı al

    if (!selectedFile) return; // Eğer dosya seçilmediyse işlem yapma

    // Seçilen dosyayı burada kullanabilirsiniz, örneğin:
    console.log("Seçilen dosya adı:", selectedFile.name);
    console.log("Dosya tipi:", selectedFile.type);
    console.log("Dosya boyutu:", selectedFile.size, "bytes");

    // Seçilen dosyayı yüklemek, işlemek veya başka bir şey yapmak için buraya kod ekleyebilirsiniz.
  };

  return (
    <div className="d-flex justify-content-center align-items-center mt-30">
      <form
        className="contact-form"
        onSubmit={handlePersonalInfo}
        style={{ width: "23rem" }}
      >
        <div className="form-grp d-flex justify-content-center align-items-center">
          <label htmlFor="fileInput">
            <div
              className="d-flex justify-content-center align-items-center mb-4"
              style={{
                backgroundColor: "#637579",
                width: "200px",
                height: "200px",
                cursor: "pointer",
              }}
            >
              <i style={{ color: "white" }} className="fas fa-camera fa-2x"></i>
            </div>
          </label>
          <input
            id="fileInput"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => handleFileSelect(e)}
          />
        </div>
        <div className="form-grp mb-4">
          <label className="form-label" htmlFor="fullname">
            {t("fullname")}
          </label>
          <input
            required
            type="text"
            id="fullname"
            defaultValue={user?.fullname}
            className="form-control"
          />
        </div>
        <div className="form-grp mb-4">
          <label className="form-label" htmlFor="email">
            {t("email")}
          </label>
          <input
            required
            type="email"
            id="email"
            defaultValue={user?.email}
            className="form-control"
          />
        </div>
        <div className="form-grp mb-4">
          <label className="form-label" htmlFor="phone">
            {t("phone")}
          </label>
          <PhoneInput
            required
            international
            defaultCountry="CA"
            value={phone}
            onChange={setPhone}
          />
        </div>
        <div className="form-grp mb-4">
          <label className="form-label" htmlFor="birthday">
            {t("birthday")}
          </label>
          <div style={{ width: "100%" }}>
            <DatePicker
              dateFormat={"dd/MM/yyyy"}
              wrapperClassName="w-100"
              selected={birthday}
              onChange={(date) => {
                setBirthday(date);
              }}
              className="form-control"
            />
          </div>
        </div>
        <div className="form-grp mb-4">
          <label className="form-label" htmlFor="city">
            {t("city")}
          </label>
          <select
            required
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "5px",
              marginBlock: "5px",
              backgroundColor: "#627479",
            }}
            id="city"
            value={selectedCity || ""}
            onChange={(e) => setSelectedCity(e.target.value)}
            className="form-select btn"
            aria-label="Default select example"
          >
            <option style={{ backgroundColor: "#0a313b" }} value="" disabled>
              {t("select")}
            </option>
            {cities &&
              cities.map((city) => (
                <option
                  key={city.sehir_id}
                  style={{ backgroundColor: "#0a313b" }}
                  value={city.sehir_adi}
                >
                  {city.sehir_adi}
                </option>
              ))}
          </select>
        </div>
        {districts && (
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="district">
              {t("district")}
            </label>
            <select
              required
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                marginBlock: "5px",
                backgroundColor: "#627479",
              }}
              id="district"
              value={selectedDistrict || ""}
              onChange={(e) => setSelectedDistrict(e.target.value)}
              className="form-select btn"
              aria-label="Default select example"
            >
              <option style={{ backgroundColor: "#0a313b" }} value="" disabled>
                {t("select")}
              </option>
              {districts.map((district) => (
                <option
                  key={district.ilce_id}
                  value={district.ilce_adi}
                  style={{ backgroundColor: "#0a313b" }}
                >
                  {district.ilce_adi}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="pt-1 my-5">
          <button
            className="btn btn-info btn-lg btn-block d-flex justify-content-center"
            type="submit"
          >
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserGeneralInfo;
