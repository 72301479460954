import React from "react";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
import Catagories from "./Catagories";
import SideBarTitle from "./SideBarTitle";
import BlogNewsletter from "./BlogNewsletter";
import { useTranslation } from "react-i18next";


function BlogContent() {
  const { t, i18n } = useTranslation();

  return (
    <section className="standard-blog-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/blog-details">
                  <img src="/img/blog/blog_thumb01.jpg" alt="" />
                </Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    <li className="tags">
                      <i className="flaticon-bookmark-1" />
                      <a href="/#">{t("siberian_husky")}</a>
                      <a href="/#">{t("breed")}</a>
                    </li>
                    <li>
                      <i className="far fa-user" />
                      <a href="/#">{t("admin")}</a>
                    </li>
                    <li>
                      <i className="far fa-bell" /> {t("Mar_10_2021")}
                    </li>
                  </ul>
                </div>
                <h2 className="title">
                  <Link to="/blog-details">
                  {t("your_pet_needs")}
                  </Link>
                </h2>
                <p>
                {t("blog_content_p")}
                </p>
                <Link to="/blog-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/blog-details">
                  <img src="/img/blog/blog_thumb02.jpg" alt="" />
                </Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    <li className="tags">
                      <i className="flaticon-bookmark-1" />
                      <a href="/#">{t("siberian_husky")}</a>
                      <a href="/#">{t("breed")}</a>
                    </li>
                    <li>
                      <i className="far fa-user" />
                      <a href="/#">{t("admin")}</a>
                    </li>
                    <li>
                      <i className="far fa-bell" /> {t("Mar_10_2021")}
                    </li>
                  </ul>
                </div>
                <h2 className="title">
                  <Link to="/blog-details">
                  {t("pets_need_special_food")}
                  </Link>
                </h2>
                <p>
                {t("blog_content_p")}
                </p>
                <Link to="/blog-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
            <div className="standard-blog-item">
              <div className="standard-blog-thumb">
                <Link to="/blog-details">
                  <img src="/img/blog/blog_thumb03.jpg" alt="" />
                </Link>
              </div>
              <div className="standard-blog-content">
                <div className="blog-post-meta">
                  <ul>
                    <li className="tags">
                      <i className="flaticon-bookmark-1" />
                      <a href="/#">{t("siberian_husky")}</a>
                      <a href="/#">{t("breed")}</a>
                    </li>
                    <li>
                      <i className="far fa-user" />
                      <a href="/#">{t("admin")}</a>
                    </li>
                    <li>
                      <i className="far fa-bell" /> {t("Mar_10_2021")}
                    </li>
                  </ul>
                </div>
                <h2 className="title">
                  <Link to="/blog-details">
                  {t("special_grades")}
                  </Link>
                </h2>
                <p>
                {t("blog_content_p")}
                </p>
                <Link to="/blog-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-8">
            <aside className="blog-sidebar">
              <SearchBar />
              <Catagories />
              <div className="widget">
                <h4 className="sidebar-title">{t("recent_post")}</h4>
                <SideBarTitle />
              </div>
              <BlogNewsletter />
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogContent;
