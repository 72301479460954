import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { Modal, Box, Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { getBreeds } from "../../store/slices/breedSlice";
import { getAllBreeds } from "../../store/slices/breedSlice";
import Swal from "sweetalert2";

function AdoptPet() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [adopts, setAdopts] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photoBase64, setPhotoBase64] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [birthday, setBirthday] = useState("");
  const breeds = useSelector((state) => state.breed.breeds);
  const allBreeds = useSelector((state) => state.breed.allBreeds);
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    const getAdopts = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}adopt/1`);
        setAdopts(res.data.adopts);
      } catch (error) {
        console.log(error);
      }
    };
    getAdopts();
  }, []);

  useEffect(() => {
    if (addModal) {
      dispatch(getAllBreeds());
    }
  }, [addModal, dispatch]);

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" className={className} onClick={onClick}>
        <img src="/img/icon/arrow.png" alt="prev" />
      </button>
    );
  }
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" className={className} onClick={onClick}>
        <img src="/img/icon/arrow.png" alt="next" />
      </button>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const handleCloseModal = () => {
    setAddModal(false);
    // setSelectedService(null);
  };

  const handleAddAdopt = () => {
    setAddModal(true);
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoBase64(reader.result); // Base64 formatına çevirir
        setSelectedPhoto(URL.createObjectURL(file)); // Fotoğrafı gösterir
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitAdopt = async (e) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        fullname: e.target.fullname.value,
        image_url: photoBase64,
        type: e.target.type.value,
        breed: e.target.breed.value,
        size: e.target.size.value,
        gender: e.target.gender.value,
        color: e.target.color.value,
        birthday: birthday,
        desc: e.target.desc.value,
        medicine: e.target.medicine.value,
        allergy: e.target.allergy.value,
        special_case: e.target.case.value,
      };

      const response = await axios.post(`${API_URL}adopt`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        setAddModal(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your pet has been successfully added",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">{t("meet_animals")}</h5>
              <h2 className="title">{t("meet_title")}</h2>
              <p>{t("adopt_description")}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="d-flex justify-content-end my-2"
            style={{ cursor: "pointer", color: "darkblue" }}
          >
            <Link className="btn" to={"/app/adopt"}>
              {t("FL_button")} <img src="/img/icon/w_pawprint.png" alt="" />
            </Link>
            <button
              onClick={() => {
                handleAddAdopt();
              }}
              className="btn ml-3"
              to={"/app/adopt"}
            >
              {t("add")} <img src="/img/icon/w_pawprint.png" alt="" />
            </button>
          </div>
          <Slider className="row adopt-active" {...settings}>
            {adopts &&
              adopts.map((adopt) => (
                <div key={adopt._id} className="col-lg-12 col-md-6">
                  <div className="adoption-shop-item">
                    <div className="adoption-shop-thumb">
                      <img
                        src={
                          adopt.image_url ||
                          "/img/product/adoption_shop_thumb01.jpg"
                        }
                        alt=""
                      />
                      <Link to={`/app/adopt/${adopt._id}`} className="btn">
                        {t("adoption")}{" "}
                        <img src="/img/icon/w_pawprint.png" alt="" />
                      </Link>
                    </div>
                    <div className="adoption-shop-content">
                      <h4 className="title">
                        <Link to={`/app/adopt/${adopt._id}`}>
                          {adopt.fullname}
                        </Link>
                      </h4>
                      <div className="adoption-meta">
                        <ul>
                          <li>
                            <i className="fas fa-cog" />
                            {adopt.breed}
                          </li>
                          <li>
                            <i className="fas fa-birthday-cake" />{" "}
                            {calculateAge(adopt.birthday)} {t("years")}
                          </li>
                        </ul>
                      </div>
                      <div className="adoption-rating">
                        <ul>
                          <li>Charlottetown</li>
                          <li className="price">
                            {t("total_price")} :{" "}
                            <span>
                              {parseInt(adopt.price) === 0
                                ? "Free"
                                : `${adopt.price} ₺`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
        <div>
          <Modal
            open={addModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className="col-md-5 col-10" sx={boxStyle}>
              <div
                onClick={handleCloseModal}
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  top: 5,
                  right: 5,
                  zIndex: 9999,
                }}
              >
                <img
                  src="/img/close.png"
                  alt="close_button"
                  width={40}
                  height={40}
                />
              </div>
              <form className="contact-form" onSubmit={handleSubmitAdopt}>
                <div className="d-flex form-grp flex-column justify-content-center align-items-center">
                  <div className="col-md-10 col mb-2">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      {t("pet_photo")}
                    </Typography>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileSelect}
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                    {selectedPhoto && (
                      <div>
                        <img
                          src={selectedPhoto}
                          alt="Selected Pet"
                          style={{
                            width: "100%",
                            height: "auto",
                            marginTop: "10px",
                            borderRadius: "5px",
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {t("pet_name")}
                    </Typography>
                    <input required id="fullname" type="text" />
                  </div>
                  <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                    <div className="d-flex col-md-5 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("pet")}
                      </Typography>
                      <select
                        required
                        style={{
                          padding: "8px",
                          backgroundColor: "#637579",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="type"
                        className="form-select btn"
                        onChange={(e) => {
                          dispatch(getBreeds(e.target.value));
                        }}
                        defaultValue=""
                        aria-label="Default select example"
                      >
                        <option
                          style={{ backgroundColor: "#0a313b" }}
                          value=""
                          disabled
                        >
                          {t("select")}
                        </option>
                        {allBreeds &&
                          allBreeds.map((allBreed, index) => (
                            <option
                              key={index}
                              value={allBreed}
                              style={{ backgroundColor: "#0a313b" }}
                            >
                              {t(allBreed)}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="d-flex col-md-5 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("breed")}
                      </Typography>
                      <select
                        required
                        style={{
                          backgroundColor: "#637579",
                          padding: "8px",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="breed"
                        className="form-select btn"
                        defaultValue=""
                        aria-label="Default select example"
                      >
                        <option
                          style={{ backgroundColor: "#0a313b" }}
                          value={""}
                          disabled
                        >
                          {t("select")}
                        </option>
                        {breeds &&
                          breeds.map((dog) => (
                            <option
                              style={{ backgroundColor: "#0a313b" }}
                              key={dog}
                              defaultValue={dog}
                            >
                              {dog}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="d-flex flex-row flex-wrap justify-content-center w-100">
                    <div className="d-flex col-12 col-md-3 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("size")}{" "}
                        <span style={{ fontWeight: "lighter" }}>
                          {t("lbs")}
                        </span>
                      </Typography>
                      <input required id="size" type="number" step={"0.1"} />
                    </div>
                    <div className="d-flex col-12 col-md-4 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("gender")}
                      </Typography>
                      <select
                        required
                        style={{
                          backgroundColor: "#637579",
                          padding: "8px",
                          borderRadius: "5px",
                          marginBlock: "5px",
                        }}
                        id="gender"
                        className="form-select btn"
                        defaultValue=""
                        aria-label="Default select example"
                      >
                        <option
                          disabled
                          value=""
                          style={{ backgroundColor: "#0a313b" }}
                        >
                          {t("select")}
                        </option>
                        <option
                          defaultValue={"male"}
                          style={{ backgroundColor: "#0a313b" }}
                        >
                          {t("male")}
                        </option>
                        <option
                          defaultValue={"female"}
                          style={{ backgroundColor: "#0a313b" }}
                        >
                          {t("female")}
                        </option>
                      </select>
                    </div>
                    <div className="d-flex col-12 col-md-3 flex-column">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                          marginTop: "10px",
                        }}
                      >
                        {t("color")}
                      </Typography>
                      <input required id="color" type="text" />
                    </div>
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      {t("birthday")}
                    </Typography>
                    <DatePicker
                      dateFormat={"dd/MM/yyyy"}
                      required
                      wrapperClassName="w-100"
                      selected={birthday}
                      onChange={(date) => {
                        setBirthday(date);
                      }}
                    />
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      {t("pet_introduction")}
                    </Typography>
                    <textarea
                      name="desc"
                      id="desc"
                      style={{
                        resize: "none",
                        height: "200px",
                      }}
                    ></textarea>
                  </div>
                  <div className="d-flex flex-row flex-wrap justify-content-center w-100 mt-2">
                    <div className="d-flex col-12 col-md-5 flex-column justify-content-center">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {t("medicines")}
                      </Typography>
                      <textarea
                        name="medicine"
                        id="medicine"
                        style={{
                          resize: "none",
                          height: "200px",
                        }}
                      ></textarea>
                    </div>
                    <div className="d-flex col-12 col-md-5 flex-column justify-content-center">
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {t("allergy")}
                      </Typography>
                      <textarea
                        name="allergy"
                        id="allergy"
                        style={{
                          resize: "none",
                          height: "200px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-10 col">
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "black",
                        marginTop: "10px",
                      }}
                    >
                      {t("special_cases")}
                    </Typography>
                    <textarea
                      name="case"
                      id="case"
                      style={{
                        resize: "none",
                        height: "200px",
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    style={{ width: "80%", justifyContent: "center" }}
                    className="btn"
                  >
                    {t("save")}
                  </button>
                </div>
              </form>
            </Box>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default AdoptPet;
