import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="/img/icon/arrow.png" alt="prev" />
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="/img/icon/arrow.png" alt="next" />
    </button>
  );
}

function GroupComponent() {
  const { t, i18n } = useTranslation();

  const [groups, setGroups] = useState();

  useEffect(() => {
    const getEvents = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}groups/1`);
        setGroups(res.data.groups);
      } catch (error) {
        console.log(error);
      }
    };
    getEvents();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  return (
    <section className="adoption-area-two pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">{t("users_groups")}</h5>
              <h2 className="title">
              {t("users_title")}
              </h2>
              <p>
              {t("FL_description")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="d-flex justify-content-end my-2"
          style={{ cursor: "pointer", color: "darkblue" }}
        >
          <Link className="btn" to={"/app/groups"}>
          {t("FL_button")}
            <img src="/img/icon/w_pawprint.png" alt="" />
          </Link>
        </div>
        <Slider className="row adopt-active" {...settings}>
          {groups &&
            groups.map((group) => (
              <div key={group._id} className="col-xl">
                <div className="adoption-item">
                  <div className="adopt-thumb">
                    <Link to={`/app/group/${group._id}`}>
                      <img
                        src={group.image_url || "/img/images/adop_img01.png"}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="adopt-content">
                    <h3 className="title">
                      <Link to={`/app/group/${group._id}`}>
                        {group.fullname}
                      </Link>
                    </h3>
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {group.desc}
                    </p>
                    <Link to={`/app/group/${group._id}`} className="read-more">
                    {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
}

export default GroupComponent;
