import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const PaymentInfoForm = () => {
  const { t, i18n } = useTranslation();
  const user = JSON.parse(localStorage.getItem("pet_user"));
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const handleIban = async (e) => {
    e.preventDefault();

    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const payload = {
      iban: e.target.iban.value,
      fullname: user.fullname,
      email: user.email,
      phone: user.phone,
      birthday: user.birthday,
    };

    try {
      const response = await axios.put(`${API_URL}users/edit_user`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });

      if (response.data) {
        localStorage.setItem("pet_user", JSON.stringify(await response.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your Iban has been successfully saved",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  return (
    <div className="d-flex my-5 justify-content-center align-items-center">
      <form className="contact-form" onSubmit={handleIban}>
        <div className="form-grp">
          <label className="form-label" htmlFor="fullname">
            Iban
          </label>
          <input
            style={{ width: "300px" }}
            type="text"
            className="form-control"
            name="iban"
            placeholder="Enter Your Iban"
            defaultValue={user?.iban}
            required
          />
        </div>
        <div className="pt-1 my-5">
          <button
            className="btn btn-info btn-lg btn-block d-flex justify-content-center"
            type="submit"
          >
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentInfoForm;
