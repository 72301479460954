import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import DetailServiceComponent from "../../../components/Ws/Provider/Services/DetailServiceComponent";
import BreadCumb from "../../../components/Breadcrumb";
import { useTranslation } from "react-i18next";

const ServiceDetail = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getAdoptDetail = async () => {
      const response = await axios.get(
        `${API_URL}service/detail/${params.id}`,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setValues(response.data);
      }
    };
    getAdoptDetail();
  }, [params, token]);

  return (
    <div>
      <BreadCumb path={t("Service_Detail")} />
      <DetailServiceComponent values={values} />
    </div>
  );
};

export default ServiceDetail;
