import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

function AdoptDetailComponent({ values }) {
  const { t } = useTranslation();

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title">{values.fullname}</h4>
              <div className="breeder-details-img">
                <img
                  src={values.image_url || "/img/lost_default.png"}
                  className="w-100"
                  alt=""
                />
              </div>
              <p>{values.desc}</p>
              <div className="breeder-dog-info">
                <h5 className="title">{t("pet_Information")}</h5>
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("gender")}:</h6>
                      <span>{values.gender}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("age")}:</h6>
                      <span>
                        {calculateAge(values.birthday)} {t("years")}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("color")}:</h6>
                      <span>{values.color}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("size")}:</h6>
                      <span>
                        {values.size} {t("lbs")}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("city")}:</h6>
                      <span>
                        {" "}
                        {values.user.city} {`- ${values.user.district}`}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("breed")}:</h6>
                      <span>{values.breed}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="breeder-dog-info">
                <h5 className="title">{t("other_Information")}</h5>
                <div className="column">
                  <div>
                    <h5>{t("medicines")}</h5>
                    <p> {values.medicine}</p>
                  </div>
                  <div>
                    <h5>{t("allergy")}</h5>
                    <p> {values.allergy}</p>
                  </div>
                  <div>
                    <h5>{t("special_cases")}</h5>
                    <p> {values.special_case}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="breeder-sidebar">
              <div className="widget breeder-widget">
                <div className="breeder-widget-title mb-20">
                  <h5 className="title">{t("adoption_request_form")}</h5>
                </div>
                <form className="sidebar-find-pets">
                  <div className="form-grp">
                    <i className="fas fa-money-bill"></i>
                    <input
                      required
                      type="number"
                      step={0.1}
                      id="price"
                      placeholder={t("price")}
                    />
                  </div>
                  <div className="form-grp">
                    <i className="fas fa-comment"></i>
                    <textarea
                      name=""
                      id="comment"
                      placeholder={t("message")}
                      style={{ resize: "none", color: "gray" }}
                      cols="5"
                      rows="4"
                    ></textarea>
                  </div>
                  <div className="form-grp">
                    <Typography style={{ fontSize: 14 }}>
                      {t("adopt_warning")}
                    </Typography>
                  </div>
                  <button className="btn">{t("send")}</button>
                </form>
              </div>
              <div className="widget sidebar-newsletter">
                <div className="sn-icon">
                  <img src="/img/icon/sn_icon.png" alt="" />
                </div>
                <div className="sn-title">
                  <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                  <p>{t("Sign_up_For_Latest_News")}</p>
                </div>
                <form className="sn-form">
                  <input type="text" placeholder={t("Enter_Your_Email")} />
                  <button className="btn">{t("newsletter_button")}</button>
                </form>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptDetailComponent;
