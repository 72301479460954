import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function AdoptionShop() {
  const { t } = useTranslation();
  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">{t("meet_animals")}</h5>
              <h2 className="title">{t("meet_title")}</h2>
              <p>
              {t("FL_description")}
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb01.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">{t("mister_tartosh")}</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">{t("siberian_husky")}</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                     {t("total_price")} : <span>{t("free")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb02.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Charlie</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">{t("golden_retriever")}</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> {t("birth")} : 2020
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                    {t("total_price")} : <span>$30</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb03.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                  {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">Alessia Max</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">{t("german_sherped")}</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> {t("birth")} : 2020
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                    {t("total_price")} : <span>$29</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb04.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">{t("canadian")}</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">{t("german_sherped")}</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                    {t("total_price")} : <span>$39</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb05.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">{t("entertainment")}</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">{t("siberian_husky")}</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                    {t("total_price")} : <span>{t("free")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="adoption-shop-item">
              <div className="adoption-shop-thumb">
                <img src="/img/product/adoption_shop_thumb06.jpg" alt="" />
                <Link to="/shop-details" className="btn">
                {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
              <div className="adoption-shop-content">
                <h4 className="title">
                  <Link to="/shop-details">{t("dangerous")}</Link>
                </h4>
                <div className="adoption-meta">
                  <ul>
                    <li>
                      <i className="fas fa-cog" />
                      <a href="/#">{t("golden_retriever")}</a>
                    </li>
                    <li>
                      <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                    </li>
                  </ul>
                </div>
                <div className="adoption-rating">
                  <ul>
                    <li className="rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </li>
                    <li className="price">
                    {t("total_price")}  : <span>{t("free")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AdoptionShop;
