import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function BreederContent() {
  const { t, i18n } = useTranslation();
  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="breeder-details-content">
              <h4 className="title">{t("siberian_husky")}</h4>
              <p>
              {t("blogpost_domestic_dog")}
              </p>
              <p>
              {t("breedercontent_p")}
              </p>
              <div className="breeder-details-img">
                <img src="/img/images/breeder_details.jpg" alt="" />
              </div>
              <h4 className="title">{t("about_bio")}</h4>
              <p>
              {t("breedercontent_p2")}
              </p>
              <div className="breeder-dog-info">
                <h5 className="title">{t("pet_Information")}</h5>
                <div className="row">
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("gender")}:</h6>
                      <span>{t("male")}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("age")}:</h6>
                      <span>1 {t("year")}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("color")}:</h6>
                      <span>{t("white")}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("pet_Id")}:</h6>
                      <span>09481</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("size")}:</h6>
                      <span>{t("med_lbt")}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("gender")}:</h6>
                      <span>{t("male")}</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("city")}:</h6>
                      <span>New York</span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-4 col-6">
                    <div className="breeder-info-item">
                      <h6>{t("breed")}:</h6>
                      <span>Husky</span>
                    </div>
                  </div>
                </div>
                <Link to="/contacts" className="btn">
                {t("apply_today")} <img src="/img/icon/w_pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <aside className="breeder-sidebar">
              <div className="widget breeder-widget">
                <div className="breeder-widget-title mb-20">
                  <h5 className="title">{t("find_your_pets")}</h5>
                </div>
                <form className="sidebar-find-pets">
                  <div className="form-grp search-box">
                    <input type="text" placeholder="Search" />
                    <button>
                      <i className="fas fa-search" />
                    </button>
                  </div>
                  <div className="form-grp">
                    <i className="flaticon-location" />
                    <input type="text" placeholder="Location" />
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-grp">
                        <i className="flaticon-color-palette" />
                        <input type="text" placeholder="White" />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-grp">
                        <i className="far fa-calendar-alt" />
                        <input type="text" defaultValue={2021} />
                      </div>
                    </div>
                  </div>
                  <div className="form-grp">
                    <i className="flaticon-sex" />
                    <select name="name" className="selected">
                      <option value>{t("female")}</option>
                      <option value>{t("male")}</option>
                      <option value>{t("adoption")}</option>
                      <option value>{t("breeder")}</option>
                    </select>
                  </div>
                  <div className="form-grp">
                    <i className="fas fa-dollar-sign" />
                    <select name="name" className="selected">
                      <option value>{t("price")}</option>
                      <option value>100₺ - 150₺</option>
                      <option value>150₺ - 250₺</option>
                      <option value>250₺ - 350₺</option>
                      <option value>350₺ - 550₺</option>
                      <option value>550₺ - 1000₺</option>
                    </select>
                  </div>
                  <div className="form-grp">
                    <i className="flaticon-plus-18-movie" />
                    <select name="name" className="selected">
                      <option value>{t("adult")} :</option>
                      <option value>6 {t("month")}</option>
                      <option value>9 {t("month")}</option>
                      <option value>1 {t("year")}</option>
                    </select>
                  </div>
                  <button className="btn">{t("find_new_pets")}</button>
                </form>
              </div>
              <div className="widget sidebar-newsletter">
                <div className="sn-icon">
                  <img src="/img/icon/sn_icon.png" alt="" />
                </div>
                <div className="sn-title">
                  <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                  <p>{t("sign_up_for_latest_news")}</p>
                </div>
                <form className="sn-form">
                  <input type="text" placeholder={t("Enter_Your_Email")} />
                  <button className="btn">{t("subscribe")}</button>
                </form>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BreederContent;
