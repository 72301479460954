import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getUserTypes = createAsyncThunk("users/types", async () => {
  try {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;
    const response = await axios.get(`${API_URL}users/types`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  types: [],
};

const userTypeSlice = createSlice({
  name: "userTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserTypes.fulfilled, (state, action) => {
      state.types = action.payload;
    });
  },
});

export default userTypeSlice.reducer;
