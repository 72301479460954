import React from "react";
import { Link } from "react-router-dom";
import HomeCounter from "../hometwo/HomeCounter";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function AboutSlider() {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 1000,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    speed: 1000,
                },
            },
        ],
    };
    return (
        <section className="inner-breeder-area breeder-bg">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-xl-4 col-lg-9 col-md-12 col-sm-9">
                        <div className="adoption-content">
                            <h5 className="sub-title">{t("meet_adoption")}</h5>
                            <h2 className="title">
                            {t("work_for")} <span>{t("adoption")}</span> {t("happy_time")}
                            </h2>
                            <p>
                            {t("meet_adoption-description")}
                            </p>
                            <div className="adoption-list">
                                <ul>
                                    <li>
                                        <i className="flaticon-tick" /> {t("meet_li1")}
                                    </li>
                                    <li>
                                        <i className="flaticon-tick" /> {t("meet_li2")}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-9 col-md-12 col-sm-9">
                        <div className="breeder-info-wrap">
                            <div className="row no-gutters justify-content-center">
                                <div className="col-md-6">
                                    <Slider className="breeder-active owl-carousel" {...settings}>
                                        <div className="breeder-item">
                                            <Link to="/breeder-details">
                                                <img src="/img/images/breeder_img01.jpg" alt="" />
                                            </Link>
                                        </div>
                                        <div className="breeder-item">
                                            <Link to="/breeder-details">
                                                <img src="/img/images/breeder_img02.jpg" alt="" />
                                            </Link>
                                        </div>
                                        <div className="breeder-item">
                                            <Link to="/breeder-details">
                                                <img src="/img/images/breeder_img03.jpg" alt="" />
                                            </Link>
                                        </div>
                                        <div className="breeder-item">
                                            <Link to="/breeder-details">
                                                <img src="/img/images/breeder_img04.jpg" alt="" />
                                            </Link>
                                        </div>
                                        <div className="breeder-item">
                                            <Link to="/breeder-details">
                                                <img src="/img/images/breeder_img05.jpg" alt="" />
                                            </Link>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="col-md-6">
                                    <div
                                        className="breed-services-info"
                                        style={{
                                            backgroundImage: 'url("img/bg/breed_services_bg.jpg")',
                                        }}
                                    >
                                        <h5 className="sub-title">{t("dog_adoption")}</h5>
                                        <h3 className="title">{t("dog_adoption_title")}</h3>
                                        <p>
                                        {t("dog_adoption_description")}
                                        </p>
                                        <Link to="/doglist" className="btn">
                                        {t("dog_adoption_button")} <img src="/img/icon/w_pawprint.png" alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="counter-area mt-70">
                    <HomeCounter />
                </div>
            </div>
        </section>
    );
}

export default AboutSlider;
