import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import ProPaymentInfoForm from '../../../components/Ws/Provider/Settings/PaymentInfoForm'
import { useTranslation } from "react-i18next";

const ProPayment = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("Payment")} />
            <ProPaymentInfoForm />
        </div>
    )
}

export default ProPayment