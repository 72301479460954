import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import UserGeneralInfo from '../../../components/Ws/User/Settings/GeneralInfo'
import { useTranslation } from "react-i18next";

const UserSettings = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("settings")} />
            <UserGeneralInfo />
        </div>
    )
}

export default UserSettings