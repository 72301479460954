import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import ChangePasswordForm from '../../../components/Ws/User/Settings/ChangePasswordForm'
import { useTranslation } from "react-i18next";

const ChangePassword = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("change_password")} />
            <div className='d-flex justify-content-center my-5'>
                <ChangePasswordForm />
            </div>
        </div>
    )
}

export default ChangePassword