import React, { useState } from 'react';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useTranslation } from "react-i18next";

const PaymentInfoForm = () => {
  const { t } = useTranslation();

    const [state, setState] = useState({
        number: '',
        expiry: '',
        cvc: '',
        name: '',
        focus: '',
    });

    const handleInputChange = (evt) => {
        const { name, value } = evt.target;

        setState((prev) => ({ ...prev, [name]: value }));
    }

    const handleInputFocus = (evt) => {
        setState((prev) => ({ ...prev, focus: evt.target.name }));
    }

    return (
        <div className="container">
            <section>
                <div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-sm-6 text-black">
                            <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-xl-0 mt-15">
                                <form
                                    className="contact-form"

                                    style={{ width: "23rem" }}
                                >
                                    <div className='my-2'>
                                        <Cards
                                            number={state.number}
                                            expiry={state.expiry}
                                            cvc={state.cvc}
                                            name={state.name}
                                            focused={state.focus}
                                        />
                                    </div>
                                    <div className="form-grp mb-4">
                                        <label className="form-label" htmlFor="fullname">
                                        {t("card_number")}
                                        </label>
                                        <input
                                            type="number"
                                            className='form-control'
                                            name="number"
                                            placeholder="Card Number"
                                            value={state.number}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="form-grp mb-4">
                                        <label className="form-label" htmlFor="email">
                                        {t("expiry")}
                                        </label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name="expiry"
                                            placeholder="Expiry"
                                            value={state.expiry}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="form-grp mb-4">
                                        <label className="form-label" htmlFor="email">
                                        {t("name_surname")}
                                        </label>
                                        <input
                                            type="text"
                                            className='form-control'
                                            name="name"
                                            placeholder="Name Surname"
                                            value={state.name}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="form-grp mb-4">
                                        <label className="form-label" htmlFor="email">
                                            CVC
                                        </label>
                                        <input
                                            type="number"
                                            className='form-control'
                                            name="cvc"
                                            placeholder="Card CVC"
                                            value={state.cvc}
                                            onChange={handleInputChange}
                                            onFocus={handleInputFocus}
                                        />
                                    </div>
                                    <div className="pt-1 my-5">
                                        <button
                                            className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                                            type="submit"
                                        >
                                            {t("save")}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PaymentInfoForm;