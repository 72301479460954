import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

export const getAllBreeds = createAsyncThunk("Allbreeds", async () => {
  try {
    const response = await axios.get(`${API_URL}breeds`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getBreeds = createAsyncThunk("breeds", async (pet) => {
  try {
    const response = await axios.get(`${API_URL}breeds/${pet}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  breeds: [],
  allBreeds: []
};

const breedSlice = createSlice({
  name: "breeds",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBreeds.fulfilled, (state, action) => {
      state.breeds = action.payload;
    });
    builder.addCase(getAllBreeds.fulfilled, (state, action) => {
      state.allBreeds = action.payload;
    });
  },
});

export default breedSlice.reducer;
