import React from "react";
import ContactAddress from "./ContactAddress";
import { useTranslation } from "react-i18next";

function ContactForm() {
  const { t, i18n } = useTranslation();

  return (
    <section className="contact-area pt-110 pb-110">
      <div className="container">
        <div className="container-inner-wrap">
          <div className="row justify-content-center justify-content-lg-between">
            <div className="col-lg-6 col-md-8 order-2 order-lg-0">
              <div className="contact-title mb-20">
                <h5 className="sub-title">{t("contact_us")}</h5>
                <h2 className="title">
                {t("contact_title")}<span>.</span>
                </h2>
              </div>
              <div className="contact-wrap-content">
                <p>
                {t("contact_description")}
                </p>
                <form className="contact-form">
                  <div className="form-grp">
                    <label htmlFor="name">
                    {t("your_name")} <span>*</span>
                    </label>
                    <input type="text" id="name" placeholder="Jon Deo..." />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="email">
                    {t("your_email")} <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      placeholder="info.example@.com"
                    />
                  </div>
                  <div className="form-grp">
                    <label htmlFor="message">
                    {t("your_message")} <span>*</span>
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      placeholder={t("Opinion")}
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-grp checkbox-grp">
                    <input type="checkbox" id="checkbox" />
                    <label htmlFor="checkbox">
                    {t("show_email")}
                    </label>
                  </div>
                  <button type="button" className="btn rounded-btn">
                  {t("send_now")}
                  </button>
                </form>
              </div>
            </div>

            <ContactAddress />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
