import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BreadCumb from "../Breadcrumb";
import { useTranslation } from "react-i18next";


function AllAdopts() {
  const { t } = useTranslation();
  const [adopts, setAdopts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const getadopts = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}adopt/${currentPage}`);
        setAdopts(res.data.adopts);
        setTotalPages(res.data.totalAdopts);
      } catch (error) {
        console.log(error);
      }
    };
    getadopts();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  return (
    <div>
      <BreadCumb path={t("adopt")} />
      <section className="adoption-shop-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon">
                  <img src="/img/icon/pawprint.png" alt="" />
                </div>
                <h5 className="sub-title">{t("meet_animals")}</h5>
                <h2 className="title">{t("meet_title")}</h2>
                <p>
                {t("meet_description")}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {adopts &&
              adopts.map((adopt) => (
                <div key={adopt._id} className="col-lg-4 col-md-6">
                  <div className="adoption-shop-item">
                    <div className="adoption-shop-thumb">
                      <img
                        src={
                          adopt.image_url ||
                          "/img/product/adoption_shop_thumb01.jpg"
                        }
                        alt=""
                      />
                      <Link to={`/app/adopt/${adopt._id}`} className="btn">
                      {t("adoption")} <img src="/img/icon/w_pawprint.png" alt="" />
                      </Link>
                    </div>
                    <div className="adoption-shop-content">
                      <h4 className="title">
                        <Link to={`/app/adopt/${adopt._id}`}>
                          {adopt.fullname}
                        </Link>
                      </h4>
                      <div className="adoption-meta">
                        <ul>
                          <li>
                            <i className="fas fa-cog" />
                            {adopt.breed}
                          </li>
                          <li>
                            <i className="fas fa-birthday-cake" />{" "}
                            {calculateAge(adopt.birthday)} {t("years")}
                          </li>
                        </ul>
                      </div>
                      <div className="adoption-rating">
                        <ul>
                          <li>Charlottetown</li>
                          <li className="price">
                             {t("total_price")} :{" "}
                            <span>
                              {parseInt(adopt.price) === 0
                                ? "Free"
                                : `${adopt.price} ₺`}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="shop-page-meta justify-content-between p-2">
            <div>{t("total_Items_1_6_of")} {totalPages}</div>
            <div>
              {Array.from({ length: Math.ceil(totalPages / 6) }, (_, index) => (
                <button
                  key={index + 1}
                  style={{
                    width: 35,
                    height: 35,
                    marginInline: 5,
                    border: "1px solid #e1e1e1",
                    borderRadius: "3px",
                    fontSize: "13px",
                    color: currentPage === index + 1 ? "white" : "black",
                    fontWeight: "500",
                    background: currentPage === index + 1 ? "red" : "white",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.background =
                      currentPage === index + 1 ? "red" : "#f0f0f0")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.background =
                      currentPage === index + 1 ? "red" : "white")
                  }
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllAdopts;
