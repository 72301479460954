import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import HistoryServicesForm from '../../../components/Ws/Provider/Services/ServiceHistoryForm'
import { useTranslation } from "react-i18next";

const ProServiceHistory = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("service_history")} />
            <HistoryServicesForm />
        </div>
    )
}

export default ProServiceHistory