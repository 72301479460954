import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import ServicesHistoryForm from '../../../components/Ws/User/Services/ServiveHistoryForm'
import { useTranslation } from "react-i18next";

const ServiceHistory = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("service_history")} />
            <ServicesHistoryForm />
        </div>
    )
}

export default ServiceHistory