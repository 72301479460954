import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL =
  process.env.REACT_APP_NODE_ENV === "production"
    ? process.env.REACT_APP_PRODUCTION_API_URL
    : process.env.REACT_APP_LOCAL_API_URL;

export const getPets = createAsyncThunk("pets/pets", async (token) => {
  try {
    const response = await axios.get(`${API_URL}pets/my_pets`, {
      headers: {
        "petapp-auth-token": token,
      }
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

const initialState = {
  pets: [],
};

const petSlice = createSlice({
  name: "pets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPets.fulfilled, (state, action) => {
      state.pets = action.payload;
    });
  },
});

export default petSlice.reducer;
