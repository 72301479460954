import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import BreadCumb from "../../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";
import { Cancel, Edit } from "@mui/icons-material";
import AddProductModal from "../../../components/Ws/Provider/Product/AddProduct";
import EditProductModal from "../../../components/Ws/Provider/Product/EditProduct";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function MyProducts() {
  const { t, i18n } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const user = JSON.parse(localStorage.getItem("pet_user"));
  const [category, setCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const query = useQuery();
  const searchQuery = query.get("search");
  const history = useHistory();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const API_URL =
          process.env.REACT_APP_NODE_ENV === "production"
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_LOCAL_API_URL;

        const response = await axios.get(`${API_URL}products/my_products`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        const filteredProducts = response.data.filter(
          (product) =>
            !searchQuery ||
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setProducts(filteredProducts);
      } catch (error) {
        console.log(error);
      }
    };
    getProducts();
  }, [searchQuery, token]);

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  });

  const handleAddButton = () => {
    if (!user.petshop) {
      Swal.fire({
        icon: "error",
        title: "No Petshop name",
        text: "Please enter your petshop name in the settings tab.",
      });
    } else {
      setModalVisible(true);
      const getCategory = async () => {
        try {
          const API_URL =
            process.env.REACT_APP_NODE_ENV === "production"
              ? process.env.REACT_APP_PRODUCTION_API_URL
              : process.env.REACT_APP_LOCAL_API_URL;

          const response = await axios.get(`${API_URL}products/category`, {
            headers: {
              "petapp-auth-token": token,
            },
          });
          setCategory(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      getCategory();
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      Swal.fire({
        icon: "warning",
        text: "Do you want to delete the product?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.delete(`${API_URL}products/${id}`, {
            headers: {
              "petapp-auth-token": token,
            },
          });
          if (response.data) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        }
        if (result.isDenied) {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClose = () => {
    setEditModalVisible(false);
    setProductToEdit(null);
  };

  const fetchProductsAndCategories = async () => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const [productsResponse, categoriesResponse] = await Promise.all([
        axios.get(`${API_URL}products/my_products`, {
          headers: {
            "petapp-auth-token": token,
          },
        }),
        axios.get(`${API_URL}products/category`, {
          headers: {
            "petapp-auth-token": token,
          },
        }),
      ]);

      setProducts(productsResponse.data);
      setCategory(categoriesResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditProduct = async (product) => {
    setEditModalVisible(true);
    setProductToEdit(product);
    fetchProductsAndCategories();
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchQuery = e.currentTarget.search.value;
    history.push(`/app/my_products?search=${searchQuery}`);
  };

  return (
    <div>
      <BreadCumb path={t("my_products")} />
      <div className="shop-area pt-60 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-8 order-2 order-lg-0">
              <aside className="shop-sidebar">
                <div className="widget">
                  <button
                    onClick={() => {
                      handleAddButton();
                    }}
                    className="btn mb-3 justify-content-center w-100"
                  >
                    {t("add_product")}
                  </button>
                </div>
              </aside>
            </div>
            <AddProductModal
              open={modalVisible}
              onClose={() => setModalVisible(false)}
              token={token}
              categories={category}
            />
            <EditProductModal
              open={editModalVisible}
              onClose={() => handleEditClose()}
              token={token}
              categories={category}
              setCategory={setCategory}
              productToEdit={productToEdit}
            />
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="search-form mb-10">
                  <form onSubmit={handleSearch}>
                    <input
                      id="search"
                      type="text"
                      name="search"
                      placeholder={t("Search_Here")}
                      defaultValue={searchQuery}
                    />
                    <button className="search-btn">
                      <i className="fas fa-search" />
                    </button>
                  </form>
                </div>
                <div className="shop-page-meta mb-30">
                  <div className="shop-grid-menu">
                    <ul>
                      <li className="active">
                        <a href="/#">
                          <i className="fas fa-th" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fas fa-list" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="shop-showing-result">
                    <p>{t("total_Items_1_12_of_13")}</p>
                  </div>
                  <div className="shop-show-list">
                    <form>
                      <label htmlFor="show">{t("show")}</label>
                      <select id="show" className="selected">
                        <option value>08</option>
                        <option value>12</option>
                        <option value>16</option>
                        <option value>18</option>
                        <option value>20</option>
                      </select>
                    </form>
                  </div>
                  <div className="shop-short-by">
                    <form>
                      <label htmlFor="shortBy">{t("sort_by")}</label>
                      <select id="shortBy" className="selected">
                        <option value>{t("Sort_by_latest")}</option>
                        <option value>{t("low_to_high")}</option>
                        <option value>{t("high_to_low")}</option>
                        <option value>{t("popularity")}</option>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {products &&
                    products.map((product, index) => (
                      <div ref={dropdownRef} key={product._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link to={`/app/my_products/${product._id}`}>
                              <img
                                src={
                                  product.images[0] ||
                                  "/img/product/product_details.jpg"
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              top: "-10px",
                              right: "10px",
                              width: "42px",
                              height: "42px",
                              backgroundColor: "#f04336",
                              color: "white",
                              padding: "0 14px",
                              borderRadius: "50%",
                              lineHeight: "42px",
                            }}
                          >
                            <i
                              className="fa fa-bars"
                              style={{ cursor: "pointer" }}
                              aria-hidden="true"
                              onClick={() => handleDropdownToggle(index)}
                            ></i>
                            {dropdownIndex === index && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: "20px",
                                  right: "0px",
                                  backgroundColor: "#fff",
                                  border: "1px solid #ccc",
                                  padding: "5px",
                                }}
                              >
                                <ul>
                                  <li>
                                    <Button
                                      style={{ color: "blue" }}
                                      onClick={() => {
                                        handleEditProduct(product);
                                      }}
                                      className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                    >
                                      <Edit
                                        style={{
                                          color: "blue",
                                          marginRight: "3px",
                                        }}
                                      />
                                      {t("edit")}
                                    </Button>
                                  </li>
                                  <li>
                                    <Button
                                      style={{ color: "red" }}
                                      onClick={() => {
                                        handleDeleteProduct(product._id);
                                      }}
                                      className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                    >
                                      <Cancel
                                        style={{
                                          color: "red",
                                          marginRight: "3px",
                                        }}
                                      />
                                      {t("delete")}
                                    </Button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="shop-content">
                            <span>
                              {product.category[product.category.length - 1]}
                            </span>
                            <h4 className="title">
                              <Link to={`/app/my_products/${product._id}`}>
                                {product.name}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="shop-page-meta">
                  <div className="shop-grid-menu">
                    <ul>
                      <li className="active">
                        <a href="/#">
                          <i className="fas fa-th" />
                        </a>
                      </li>
                      <li>
                        <a href="/#">
                          <i className="fas fa-list" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="shop-showing-result">
                    <p>{t("total_Items_1_12_of_13")}</p>
                  </div>
                  <div className="shop-show-list">
                    <form>
                      <label htmlFor="bottomShow">{t("show")}</label>
                      <select id="bottomShow" className="selected">
                        <option value>08</option>
                        <option value>12</option>
                        <option value>16</option>
                        <option value>18</option>
                        <option value>20</option>
                      </select>
                    </form>
                  </div>
                  <div className="shop-pagination">
                    <ul>
                      <li className="active">
                        <Link to="/shop">1</Link>
                      </li>
                      <li>
                        <Link to="/shop">2</Link>
                      </li>
                      <li>
                        <Link to="/shop">
                          <i className="fas fa-angle-double-right" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyProducts;
