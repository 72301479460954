import axios from "axios";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const PersonalInfo = () => {
  const { t, i18n } = useTranslation();
  const user = JSON.parse(localStorage.getItem("pet_user"));
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const [birthday, setBirthday] = useState(user?.birthday);

  const handlePersonalInfo = async (e) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        fullname: e.currentTarget.fullname.value,
        email: e.currentTarget.email.value,
        phone: e.currentTarget.phone.value,
        city: e.currentTarget.city.value,
        district: e.currentTarget.district.value,
        neighbourhood: e.currentTarget.neighbourhood.value,
      };

      const res = await axios.put(`${API_URL}users/edit_user`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (res.data) {
        localStorage.setItem("pet_user", JSON.stringify(await res.data));
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your informations has been successfully saved",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container">
      <section>
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center">
            <div className="col-sm-6 text-black">
              <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-xl-0 mt-15">
                <form
                  className="contact-form"
                  onSubmit={handlePersonalInfo}
                  style={{ width: "23rem" }}
                >
                  <h3
                    className="fw-normal mb-3 pb-3"
                    style={{ letterSpacing: "1px" }}
                  >
                    {t("personal_Informations")}
                  </h3>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="fullname">
                      {t("fullname")}
                    </label>
                    <input
                      required
                      type="text"
                      id="fullname"
                      defaultValue={user?.fullname}
                      className="form-control"
                    />
                  </div>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="email">
                      {t("email")}
                    </label>
                    <input
                      required
                      type="email"
                      id="email"
                      defaultValue={user?.email}
                      className="form-control"
                    />
                  </div>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="phone">
                      {t("phone")}
                    </label>
                    <input
                      required
                      type="text"
                      id="phone"
                      defaultValue={user?.phone}
                      className="form-control"
                    />
                  </div>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="birthday">
                      {t("birthday")}
                    </label>
                    <div style={{ width: "100%" }}>
                      <DatePicker
                        dateFormat={"dd/MM/yyyy"}
                        wrapperClassName="w-100"
                        selected={birthday}
                        onChange={(date) => {
                          setBirthday(date);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="city">
                      {t("city")}
                    </label>
                    <select
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        marginBlock: "5px",
                      }}
                      id="city"
                      defaultValue={user?.city}
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option style={{ backgroundColor: "#0a313b" }} disabled>
                        {t("city")}
                      </option>

                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        defaultValue={1}
                      >
                        {"Toronto"}
                      </option>
                    </select>
                  </div>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="district">
                      {t("district")}
                    </label>
                    <select
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        marginBlock: "5px",
                      }}
                      id="district"
                      defaultValue={user?.district}
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option style={{ backgroundColor: "#0a313b" }} disabled>
                        {t("district")}
                      </option>

                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        defaultValue={1}
                      >
                        {"İlçe"}
                      </option>
                    </select>
                  </div>
                  <div className="form-grp mb-4">
                    <label className="form-label" htmlFor="neighbourhood">
                      {t("neighbourhood")}
                    </label>
                    <select
                      style={{
                        width: "100%",
                        padding: "8px",
                        borderRadius: "5px",
                        marginBlock: "5px",
                      }}
                      id="neighbourhood"
                      defaultValue={user?.neighbourhood}
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option style={{ backgroundColor: "#0a313b" }} disabled>
                        {t("neighbourhood")}
                      </option>

                      <option
                        style={{ backgroundColor: "#0a313b" }}
                        defaultValue={1}
                      >
                        {"Mahalle"}
                      </option>
                    </select>
                  </div>
                  <div className="pt-1 mb-4">
                    <button
                      className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                      type="submit"
                    >
                      {t("save")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PersonalInfo;
