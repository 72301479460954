import React from 'react';
import {Link} from 'react-router-dom';
import { useTranslation } from "react-i18next";
function BreederDetBread() {
  const { t, i18n } = useTranslation();
  return (
	  <section className="breadcrumb-area breadcrumb-bg" style={{ backgroundImage:'url("img/bg/breadcrumb_bg.jpg")'}}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-content">
                <h2 className="title">{t("breeder_details")}</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{t("home")}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{t("Breeder_single")}</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}
export default BreederDetBread;
