import React from "react";
import ShopBreadcumb from "../components/ourshop/ShopBreadcumb";
import ShopArea from "../components/ourshop/ShopArea";

function ShopPages() {
  return (
    <main>
      <ShopBreadcumb />
      <ShopArea />
    </main>
  );
}
export default ShopPages;
