import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import BreadCumb from "../../../Breadcrumb";
import { Typography } from "@mui/material";
import StarRatings from "react-star-ratings";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const ProHistoryServicesDetail = () => {
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();
  const [rating, setRating] = useState(0);
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getAdoptDetail = async () => {
      const response = await axios.get(
        `${API_URL}service/detail/${params.id}`,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setValues(response.data);
      }
    };
    getAdoptDetail();
  }, [params, token]);

  const changeRating = (newRating, name) => {
    setRating(newRating);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const handleFeedback = async (e) => {
    e.preventDefault();

    if (rating === 0) {
      Swal.fire({
        icon: "error",
        title: "No Rating",
        text: "Please enter rating.",
      });
      return;
    }

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        providerToUser: {
          star: rating,
          comment: e.target.comment.value,
        },
      };

      const response = await axios.put(
        `${API_URL}service/feedback/${values._id}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );

      if (response.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your feedback has been successfully sent",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong",
      });
    }
  };

  return (
    <div>
      <BreadCumb path={t("Service_Detail")} />
      <div>
        {values && (
          <section className="breeder-details-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="breeder-details-content">
                    <h4 className="title">{values.pet.fullname}</h4>
                    <p>{values.pet.desc}</p>
                    <div className="breeder-details-img">
                      <img
                        src={values.pet.pet_image || "/img/pet/default.png"}
                        alt=""
                      />
                    </div>
                    <div className="breeder-dog-info">
                      <h5 className="title">{t("pet_Information")}</h5>
                      <div className="row">
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("gender")}:</h6>
                            <span>{t(values.pet.gender)}</span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("age")}:</h6>
                            <span>
                              {calculateAge(values.pet.birthday)} {t("years")}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("color")}:</h6>
                            <span>{values.pet.color}</span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("size")}:</h6>
                            <span>
                              {values.pet.size} {t("lbs")}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("city")}:</h6>
                            <span>
                              {values.user.city} {`-${values.user.district}`}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-4 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("breed")}:</h6>
                            <span>{values.pet.breed}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="breeder-dog-info">
                      <h5 className="title">{t("other_Information")}</h5>
                      <div className="column">
                        <div>
                          <h5>{t("medicines")}</h5>
                          <p> {values.pet.medicine}</p>
                        </div>
                        <div>
                          <h5>{t("allergy")}</h5>
                          <p> {values.pet.allergy}</p>
                        </div>
                        <div>
                          <h5>{t("special_cases")}</h5>
                          <p> {values.pet.special_case}</p>
                        </div>
                      </div>
                    </div>
                    <div className="breeder-dog-info">
                      <h5 className="title">{t("request_Information")}</h5>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("created_date")}:</h6>
                            <span>{formatDate(values.createdAt)}</span>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("service_category")}:</h6>
                            <span>{t(values.job)}</span>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("start_date")}:</h6>
                            <span>
                              {formatDate(values.dates.startDate)}{" "}
                              {formatTime(values.dates.startDate)}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-6">
                          <div className="breeder-info-item">
                            <h6>{t("end_date")}:</h6>
                            <span>
                              {formatDate(values.dates.endDate)}{" "}
                              {formatTime(values.dates.endDate)}
                            </span>
                          </div>
                        </div>
                      </div>
                      {values.desc && (
                        <div className="breeder-dog-info">
                          <h5 className="title">{t("pet_owner_note")}</h5>
                          <p>{values?.desc}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="breeder-sidebar">
                    {values.user && (
                      <div>
                        <div className="widget breeder-widget">
                          <div className="breeder-widget-title d-flex justify-content-center flex-column align-items-center">
                            {values.offers[0].status === 3 ? (
                              <img
                                src="/img/offer/complated.png"
                                alt="cancel"
                                width={100}
                              />
                            ) : (
                              <img
                                src="/img/offer/cancelled.png"
                                alt="cancel"
                                width={100}
                              />
                            )}
                            <Typography className="mt-3">
                              {values.offers[0].status === 5
                                ? t("By_Provider")
                                : values.offers[0].status === 4
                                ? t("By_Owner")
                                : null}
                            </Typography>
                          </div>
                        </div>
                        <div className="widget breeder-widget p-3">
                          <h4
                            className="my-2"
                            style={{
                              borderBottom: "1px solid #ccc",
                              fontWeight: "700",
                              color: "#0a303a",
                              textAlign: "center",
                              paddingBottom: "10px",
                            }}
                          >
                            {t("owner")}
                          </h4>
                          <div
                            style={{ gap: 10 }}
                            className="breeder-widget-title mb-20 d-flex justify-content-center flex-column align-items-center"
                          >
                            <Link to={`/app/user/${values.user._id}`}>
                              <img
                                src="/img/unuser.png"
                                alt="unuser"
                                style={{ borderRadius: "8px" }}
                                width={100}
                              />
                            </Link>
                            <Link to={`/app/user/${values.user._id}`}>
                              <Typography>{values.user.fullname}</Typography>
                            </Link>
                          </div>
                        </div>
                        <div className="widget breeder-widget p-3 mb-4">
                          <div className="breeder-widget-title mb-20">
                            <h4
                              className="my-2"
                              style={{
                                borderBottom: "1px solid #ccc",
                                fontWeight: "700",
                                color: "#0a303a",
                                textAlign: "center",
                                paddingBottom: "10px",
                              }}
                            >
                              {t("your_offer")}
                            </h4>
                            <div className="d-flex justify-content-start flex-column align-items-start">
                              <Typography>
                                <span style={{ fontWeight: "bold" }}>
                                  {t("price")}:
                                </span>{" "}
                                {values?.offers[0]?.price} ${" "}
                              </Typography>
                              <Typography>
                                <span style={{ fontWeight: "bold" }}>
                                  {t("message")}:
                                </span>{" "}
                                {values?.offers[0]?.comment}{" "}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <div className="widget breeder-widget p-3 mb-4">
                          {values.feedback?.providerToUser ? (
                            <div>
                              <h4
                                className="my-2"
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  fontWeight: "700",
                                  color: "#0a303a",
                                  textAlign: "center",
                                  paddingBottom: "10px",
                                }}
                              >
                                {t("your_vote")}
                              </h4>
                              <div className="breeder-widget-title mb-20 d-flex justify-content-center flex-column align-items-center">
                                <StarRatings
                                  rating={values.feedback.providerToUser.star}
                                  starHoverColor="orange"
                                  starRatedColor="orange"
                                  starDimension="32px"
                                  changeRating={changeRating}
                                  numberOfStars={5}
                                  name="rating"
                                />
                                <textarea
                                  name="comment"
                                  id="comment"
                                  defaultValue={
                                    values.feedback.providerToUser.comment
                                  }
                                  style={{
                                    resize: "none",
                                    marginTop: "20px",
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "8px",
                                    border: "1px solid #D3D3D3",
                                  }}
                                ></textarea>
                              </div>
                            </div>
                          ) : (
                            <form onSubmit={handleFeedback}>
                              <div className="breeder-widget-title mb-20 d-flex justify-content-center flex-column align-items-center">
                                <StarRatings
                                  rating={rating}
                                  starHoverColor="orange"
                                  starRatedColor="orange"
                                  starDimension="32px"
                                  changeRating={changeRating}
                                  numberOfStars={5}
                                  name="rating"
                                />
                                <Typography
                                  style={{ textAlign: "center" }}
                                  className="px-2 mt-5 mb-2"
                                >
                                  {t("please_write_your_thoughts_about")}
                                </Typography>
                                <textarea
                                  name="comment"
                                  id="comment"
                                  style={{
                                    resize: "none",
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "8px",
                                    border: "1px solid #D3D3D3",
                                  }}
                                ></textarea>
                                <div className="pt-1 w-100">
                                  <button
                                    className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                                    type="submit"
                                  >
                                    {t("save")}
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="widget sidebar-newsletter">
                      <div className="sn-icon">
                        <img src="/img/icon/sn_icon.png" alt="" />
                      </div>
                      <div className="sn-title">
                        <h4 className="title">{t("Subscribe_Newsletter")}</h4>
                        <p>{t("sign_up_for_latest_news")}</p>
                      </div>
                      <form className="sn-form">
                        <input
                          type="text"
                          placeholder={t("Enter_Your_Email")}
                        />
                        <button className="btn">{t("subscribe")}</button>
                      </form>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default ProHistoryServicesDetail;
