import React, { useState, useEffect } from "react";
import BreadCumb from "../../../components/Breadcrumb";
import { useParams } from "react-router-dom";
import axios from "axios";
import NewsDetailComponent from "../../../components/News/NewsDetail";
import { useTranslation } from "react-i18next";

const NewsDetail = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();

  useEffect(() => {
    const getEventDetail = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(
        `${API_URL}info/detail/news/${params.id}`
      );
      if (response.data) {
        setValues(response.data);
      }
    };
    getEventDetail();
  }, [params]);

  return (
    <div>
      <BreadCumb path={t("News_Detail")} />
      {values && <NewsDetailComponent values={values} />}
    </div>
  );
};

export default NewsDetail;
