import React from 'react';
import { useTranslation } from "react-i18next";

function SearchBar() {
  const { t, i18n } = useTranslation();
  return (
        <div className="widget">
                  <h4 className="sidebar-title">{t("search")}</h4>
                     <div className="sidebar-search">
                        <form>
                          <input type="text" placeholder="Search ..." />
                          <button type="submit"><i className="fa fa-search" /></button>          
                        </form>          
                      </div>
          </div>
	  
  )
}

export default SearchBar;
