import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function PrevArrow(props) {

  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="/img/icon/arrow.png" alt="prev" />
    </button>
  );
}
function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button type="button" className={className} onClick={onClick}>
      <img src="/img/icon/arrow.png" alt="next" />
    </button>
  );
}

function AdoptionPuppies() {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };
  return (
    <section className="adoption-area-two pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">{t("meet_animals")}</h5>
              <h2 className="title">{t("meet_title")}</h2>
              <p>
              {t("meet_description")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container custom-container">
        <Slider className="row adopt-active" {...settings}>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                <Link to="/breeder-details">
                  <img src="/img/images/adop_img01.png" alt="" />
                </Link>
                <span className="status">{t("free")}</span>
              </div>
              <div className="adopt-content">
                <div className="adopt-date">
                  <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                </div>
                <h3 className="title">
                  <Link to="/breeder-details">{t("golden_retriever")}</Link>
                </h3>
                <p>
                {t("the_golden_retriever_medium_large")}
                </p>
                <Link to="/breeder-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                <Link to="/breeder-details">
                  <img src="/img/images/adop_img02.png" alt="" />
                </Link>
                <span className="status">{t("free")}</span>
              </div>
              <div className="adopt-content">
                <div className="adopt-date">
                  <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                </div>
                <h3 className="title">
                  <Link to="/breeder-details">{t("german_sherped")}</Link>
                </h3>
                <p>{t("adoption_puppies_german")}</p>
                <Link to="/breeder-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                <Link to="/breeder-details">
                  <img src="/img/images/adop_img03.png" alt="" />
                </Link>
                <span className="status">{t("free")}</span>
              </div>
              <div className="adopt-content">
                <div className="adopt-date">
                  <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                </div>
                <h3 className="title">
                  <Link to="/breeder-details">{t("siberian_husky")}</Link>
                </h3>
                <p>
                {t("adoption_puppies_siberian")}
                </p>
                <Link to="/breeder-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                <Link to="/breeder-details">
                  <img src="/img/images/adop_img04.png" alt="" />
                </Link>
                <span className="status">{t("free")}</span>
              </div>
              <div className="adopt-content">
                <div className="adopt-date">
                  <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                </div>
                <h3 className="title">
                  <Link to="/breeder-details">{t("french_bulldog")}</Link>
                </h3>
                <p>
                {t("adoption_puppies_french")}
                </p>
                <Link to="/breeder-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl">
            <div className="adoption-item">
              <div className="adopt-thumb">
                <Link to="/breeder-details">
                  <img src="/img/images/adop_img05.png" alt="" />
                </Link>
                <span className="status">{t("free")}</span>
              </div>
              <div className="adopt-content">
                <div className="adopt-date">
                  <i className="far fa-calendar-alt" /> {t("birth")} : 2021
                </div>
                <h3 className="title">
                  <Link to="/breeder-details">{t("siberian_husky")}</Link>
                </h3>
                <p>
                {t("adoption_puppies_french")}
                </p>
                <Link to="/breeder-details" className="read-more">
                {t("read_more")} <img src="/img/icon/pawprint.png" alt="" />
                </Link>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default AdoptionPuppies;
