import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import BreadCumb from "../Breadcrumb";
import { useTranslation } from "react-i18next";

function AllNews() {
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    const getNews = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}info/news/${currentPage}`);
        setNews(res.data.infos);
        setTotalPages(res.data.total);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  return (
    <div>
      <BreadCumb path={t("News")} />
      <section className="blog-area pt-110 pb-60">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title text-center mb-65">
                <div className="section-icon">
                  <img src="/img/icon/pawprint.png" alt="" />
                </div>
                <h5 className="sub-title">{t("our_news")}</h5>
                <h2 className="title">{t("latest_news_update")}</h2>
                <p>{t("news_description")}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {news &&
              news.map((info) => (
                <div key={info._id} className="col-lg-4 col-md-6">
                  <div className="blog-post-item mb-50">
                    <div className="blog-post-thumb">
                      <Link to={`/app/news/${info._id}`}>
                        <img
                          src={
                            info.image_url || "/img/blog/blog_post_thumb01.jpg"
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="blog-post-content">
                      <div className="blog-post-meta">
                        <ul>
                          <li>
                            <i className="far fa-user" /> {t("admin")}
                          </li>
                          <li>
                            <i className="far fa-bell" />{" "}
                            {formatDate(info.createdAt)}
                          </li>
                        </ul>
                      </div>
                      <h3 className="title">
                        <Link to={`/app/news/${info._id}`}>
                          {info.fullname}
                        </Link>
                      </h3>
                      <p
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          lineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {info.desc}
                      </p>
                      <Link to={`/app/news/${info._id}`} className="read-more">
                        {t("read_more")}{" "}
                        <img src="/img/icon/pawprint.png" alt="" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="shop-page-meta justify-content-between p-2">
            <div>
              {t("total_Items_1_6_of")} {totalPages}
            </div>
            <div>
              {Array.from({ length: Math.ceil(totalPages / 6) }, (_, index) => (
                <button
                  key={index + 1}
                  style={{
                    width: 35,
                    height: 35,
                    marginInline: 5,
                    border: "1px solid #e1e1e1",
                    borderRadius: "3px",
                    fontSize: "13px",
                    color: currentPage === index + 1 ? "white" : "black",
                    fontWeight: "500",
                    background: currentPage === index + 1 ? "red" : "white",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.background =
                      currentPage === index + 1 ? "red" : "#f0f0f0")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.background =
                      currentPage === index + 1 ? "red" : "white")
                  }
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllNews;
