import React from "react";
import BreadCumb from "../../../Breadcrumb";
import { useTranslation } from "react-i18next";

function ServiceHistoryDetail() {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("Service_History_Detail")} />
            <section className="shop-details-area pt-110 pb-50">
                <div className="container">
                    <div className="shop-details-wrap">
                        <div className="row">
                            <div className="col-7">
                                <div className="shop-details-img-wrap">
                                    <div className="tab-content" id="myTabContent">
                                        <div
                                            className="tab-pane show active"
                                            id="item-one"
                                            role="tabpanel"
                                            aria-labelledby="item-one-tab"
                                        >
                                            <div className="shop-details-img">
                                                <img src="/img/product/shop_details01.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane"
                                            id="item-two"
                                            role="tabpanel"
                                            aria-labelledby="item-two-tab"
                                        >
                                            <div className="shop-details-img">
                                                <img src="/img/product/shop_details02.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane"
                                            id="item-three"
                                            role="tabpanel"
                                            aria-labelledby="item-three-tab"
                                        >
                                            <div className="shop-details-img">
                                                <img src="/img/product/shop_details03.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane"
                                            id="item-four"
                                            role="tabpanel"
                                            aria-labelledby="item-four-tab"
                                        >
                                            <div className="shop-details-img">
                                                <img src="/img/product/shop_details04.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="shop-details-content">
                                    <span>{t("walker")}</span>
                                    <h2 className="title">Jack</h2>
                                    <p>
                                    {t("historydetail_p")}
                                    </p>
                                    <div className="shop-details-bottom">
                                        <ul>
                                            <li className="d-flex align-items-center">
                                                <span className="title">{t("service_provider")} :</span>
                                                <span style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>John Doe</span>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <span className="title">{t("date")} :</span>
                                                <span style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>03/05/2024</span>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <span className="title">SKU :</span>
                                                <span style={{ color: 'gray', fontSize: 14, fontWeight: 'normal' }}>ABC123</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default ServiceHistoryDetail;
