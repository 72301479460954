import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "react-switch";
import BreadCumb from "../../components/Breadcrumb";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useTranslation } from "react-i18next";

function Register() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [type, setType] = useState(true);
  const [phone, setPhone] = useState("+1");

  const handleChangeRegisterType = (e) => {
    setType(e);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!isValidPhoneNumber(phone)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Phone Number",
        text: "Please enter a valid phone number.",
      });
      return;
    }

    if (!validatePassword(e.currentTarget.password.value)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Password",
        text: "Password must be at least 6 characters long, contain at least one uppercase letter and one number.",
      });
      return;
    }

    try {
      const payload = {
        fullname: e.currentTarget.fullname.value,
        email: e.currentTarget.email.value,
        phone: phone,
        registerType: type ? "User" : "Provider",
        password: e.currentTarget.password.value,
        confirm_password: e.currentTarget.confirm_password.value,
      };

      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const res = await axios.post(`${API_URL}auth/signup`, payload);

      if (res.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You registered successfully",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/login");
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  return (
    <div>
      <BreadCumb path={t("register")} />
      <div className="container">
        <section className="my-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6 text-black">
                <div className="d-flex align-items-center h-custom-2 px-5 ms-xl-4 pt-xl-0 mt-15">
                  <form
                    className="contact-form"
                    onSubmit={handleRegister}
                    style={{ width: "23rem" }}
                  >
                    <div className="form-outline mb-4 d-flex flex-column align-items-center justify-content-center">
                      <label className="form-label" htmlFor="register_type">
                      {t("register_type")}
                      </label>
                      <Switch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor="#f14337"
                        onChange={(e) => {
                          handleChangeRegisterType(e);
                        }}
                        checked={type}
                      />
                      <span className="register-type-span">{type ? t("Getting_Service") : t("service_provider")}</span>
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="fullname">
                      {t("fullname")}
                      </label>
                      <input
                        required
                        type="text"
                        id="fullname"
                      />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="email">
                      {t("email")}
                      </label>
                      <input
                        required
                        type="email"
                        id="email"
                      />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="phone">
                      {t("phone")}
                      </label>
                      <PhoneInput
                        required
                        international
                        defaultCountry="CA"
                        value={phone}
                        onChange={setPhone}
                      />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="password">
                      {t("login_password")}
                      </label>
                      <input
                        required
                        type="password"
                        id="password"
                      />
                    </div>
                    <div className="form-grp mb-4">
                      <label className="form-label" htmlFor="confirm_password">
                      {t("confirm_password")}
                      </label>
                      <input
                        required
                        type="password"
                        id="confirm_password"
                      />
                    </div>
                    <div className="pt-1 mb-4">
                      <button
                        className="btn btn-info btn-lg btn-block d-flex justify-content-center"
                        type="submit"
                      >
                        {t("register")}
                      </button>
                    </div>
                    <p>
                    {t("you_already_have_an_account")}{" "}
                      <Link to="/login" className="link-info">
                      {t("login_here")}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
              <div className="align-items-center justify-content-center d-flex">
                <img
                  src="/img/blog/blog_details_img01.jpg"
                  alt="Register"
                  className="w-auto rounded"
                  style={{
                    objectFit: "cover",
                    objectPosition: "left",
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Register;
