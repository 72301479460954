import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import PendingServicesForm from '../../../components/Ws/Provider/Services/PendingServiceForm'
import { useTranslation } from "react-i18next";

const PendingService = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("pending_services")} />
            <PendingServicesForm />
        </div>
    )
}

export default PendingService