import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function NewsComponent() {
  const [news, setNews] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const getNews = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}info/news/1`);
        setNews(res.data.infos);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, []);

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" className={className} onClick={onClick}>
        <img src="/img/icon/arrow.png" alt="prev" />
      </button>
    );
  }
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" className={className} onClick={onClick}>
        <img src="/img/icon/arrow.png" alt="next" />
      </button>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  return (
    <section className="adoption-area-two pt-110 pb-110">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">{t("our_news")}</h5>
              <h2 className="title">{t("news_title")}</h2>
              <p>{t("news_description")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div
          className="d-flex justify-content-end my-2"
          style={{ cursor: "pointer", color: "darkblue" }}
        >
          <Link className="btn" to={"/app/news"}>
            {t("news_button")}
            <img src="/img/icon/w_pawprint.png" alt="" />
          </Link>
        </div>
        <Slider className="row adopt-active" {...settings}>
          {news &&
            news.map((info) => (
              <div key={info._id} className="col-xl">
                <div className="blog-post-item mb-50">
                  <div className="blog-post-thumb">
                    <Link to={`/app/news/${info._id}`}>
                      <img
                        src={
                          info.image_url || "/img/blog/blog_post_thumb01.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="blog-post-content">
                    <div className="blog-post-meta">
                      <ul>
                        <li>
                          <i className="far fa-user" /> {t("admin")}
                        </li>
                        <li>
                          <i className="far fa-bell" />{" "}
                          {formatDate(info.createdAt)}
                        </li>
                      </ul>
                    </div>
                    <h3 className="title">
                      <Link to={`/app/news/${info._id}`}>{info.fullname}</Link>
                    </h3>
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        lineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {info.desc}
                    </p>
                    <Link to={`/app/news/${info._id}`} className="read-more">
                      {t("read_more")}{" "}
                      <img src="/img/icon/pawprint.png" alt="" />
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
}

export default NewsComponent;
