import React from 'react'
import OurServicesSlider from '../components/OurServices'
import BreadCumb from '../components/Breadcrumb'
import { useTranslation } from "react-i18next";

const OurServices = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("our_services")} />
            <OurServicesSlider />
        </div>
    )
}

export default OurServices