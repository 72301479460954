import React from 'react'
import AboutSlider from '../components/About'
import BreadCumb from '../components/Breadcrumb'
import { useTranslation } from "react-i18next";
const About = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("about")} />
            <AboutSlider />
        </div>
    )
}

export default About