import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Button, Modal, Box } from "@mui/material";
import { Cancel, Edit } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function PendingServicesForm() {
  const { t, i18n } = useTranslation();
  const [services, setServices] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [editOffer, setEditOffer] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    const checkCategory = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      try {
        const response = await axios.get(`${API_URL}category/check-category`, {
          headers: {
            "petapp-auth-token": token,
          },
        });
        if (!response.data.status) {
          getServices();
        }
      } catch (error) {
        console.log(error);
      }
    };
    const getServices = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(
          `${API_URL}service/all_services/${currentPage}`,
          {
            headers: {
              "petapp-auth-token": token,
            },
          }
        );
        setServices(res.data.services);
        setTotalPages(res.data.services.length);
      } catch (error) {
        console.log(error);
      }
    };
    checkCategory();
  }, [currentPage, token]);

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "50%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const handleDropdownToggle = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  const handleCancel = async (serviceId) => {
    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      Swal.fire({
        icon: "question",
        text: "Do you want to cancel the offer?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios.delete(`${API_URL}offers/${serviceId}`, {
            headers: {
              "petapp-auth-token": token,
            },
          });
          if (response.data) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        }
        if (result.isDenied) {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCloseModal = () => {
    setEditOffer(false);
    setSelectedOffer(null);
  };
  const handleEdit = async (e) => {
    e.preventDefault();

    if (!e.target.price.value) {
      setEditOffer(false);
      Swal.fire({
        icon: "error",
        title: "No Price",
        text: "Please enter price.",
      });
      return;
    }

    if (!e.target.comment.value) {
      setEditOffer(false);
      Swal.fire({
        icon: "error",
        title: "No Comment",
        text: "Please enter comment.",
      });
      return;
    }

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        price: e.target.price.value,
        comment: e.target.comment.value,
      };

      const response = await axios.put(
        `${API_URL}offers/${selectedOffer._id}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );
      if (response.data) {
        setEditOffer(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your offer is edited",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="shop-area pt-60 pb-60">
      {services ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="shop-wrap">
                <div className="row justify-content-center">
                  {services &&
                    services.map((service, index) => (
                      <div key={service._id} className="col-lg-4 col-sm-6">
                        <div className="shop-item mb-55">
                          <div className="shop-thumb">
                            <Link
                              to={`/app/pro_pending_services/${service._id}`}
                            >
                              {service.job === "Care" ? (
                                <img src="/img/service/care.jpg" alt="" />
                              ) : service.job === "Training" ? (
                                <img src="/img/service/training.jpg" alt="" />
                              ) : service.job === "Hotel" ? (
                                <img src="/img/service/hotel.jpg" alt="" />
                              ) : service.job === "Group Walking" ? (
                                <img
                                  src="/img/service/group-walking.jpg"
                                  alt=""
                                />
                              ) : service.job === "Walking" ? (
                                <img src="/img/service/walking.jpg" alt="" />
                              ) : service.job === "Keeper" ? (
                                <img src="/img/service/keeper.jpg" alt="" />
                              ) : service.job === "Hotel Pension" ? (
                                <img
                                  src="/img/service/hotel-pension.jpg"
                                  alt=""
                                />
                              ) : null}
                            </Link>
                          </div>
                          {service.offered && (
                            <div
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "10px",
                                width: "42px",
                                height: "42px",
                                backgroundColor: "#f04336",
                                color: "white",
                                padding: "0 14px",
                                borderRadius: "50%",
                                lineHeight: "42px",
                              }}
                            >
                              <i
                                className="fa fa-bars"
                                style={{ cursor: "pointer" }}
                                aria-hidden="true"
                                onClick={() => handleDropdownToggle(index)}
                              ></i>
                              {dropdownIndex === index && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "20px",
                                    right: "0px",
                                    backgroundColor: "#fff",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                  }}
                                >
                                  <ul>
                                    <li>
                                      <Button
                                        style={{ color: "blue" }}
                                        onClick={() => {
                                          setSelectedOffer(service);
                                          setEditOffer(true);
                                        }}
                                        className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                      >
                                        <Edit
                                          style={{
                                            color: "blue",
                                            marginRight: "3px",
                                          }}
                                        />
                                        {t("edit")}
                                      </Button>
                                    </li>
                                    <li>
                                      <Button
                                        style={{ color: "red" }}
                                        onClick={() => {
                                          handleCancel(service._id);
                                        }}
                                        className="d-flex align-items-center justify-content-start flex-row m-1 w-100"
                                      >
                                        <Cancel
                                          style={{
                                            color: "red",
                                            marginRight: "3px",
                                          }}
                                        />
                                        {t("cancel")}
                                      </Button>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          )}
                          <div className="shop-content">
                            <span>
                              {t(service.job)} {`(${t(service.pet.type)})`}{" "}
                            </span>
                            <h4 className="title">
                              <Link
                                to={`/app/pro_pending_services/${service._id}`}
                              >
                                {service.pet?.fullname}
                              </Link>
                            </h4>
                            <span> {`${service.userDetails.district}`}</span>
                            <div className="shop-content-bottom">
                              <span>
                                {formatDate(service?.dates.startDate)}
                              </span>
                              {service.offered ? (
                                <span style={{ color: "orange" }}>
                                  {t("offered")}
                                </span>
                              ) : (
                                <Link
                                  to={`/app/pro_pending_services/${service._id}`}
                                  style={{ color: "#09d909" }}
                                >
                                  {t("give_offer")}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {editOffer && (
                    <div>
                      <Modal
                        open={editOffer}
                        onClose={handleEditCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box className="col-md-5 col-10" sx={boxStyle}>
                          <div
                            onClick={handleEditCloseModal}
                            style={{
                              position: "absolute",
                              cursor: "pointer",
                              top: 5,
                              right: 5,
                              zIndex: 9999,
                            }}
                          >
                            <img
                              src="/img/close.png"
                              alt="close_button"
                              width={40}
                              height={40}
                            />
                          </div>
                          <form
                            className="sidebar-find-pets d-flex justify-content-center"
                            onSubmit={handleEdit}
                          >
                            <div className="w-75 mt-10">
                              <div className="form-grp">
                                <i className="fas fa-money-bill"></i>
                                <input
                                  type="text"
                                  defaultValue={selectedOffer.userOffer.price}
                                  id="price"
                                  placeholder="Price"
                                />
                              </div>
                              <div className="form-grp">
                                <i className="fas fa-comment"></i>
                                <textarea
                                  name=""
                                  id="comment"
                                  defaultValue={selectedOffer.userOffer.comment}
                                  placeholder="Message"
                                  style={{ resize: "none", color: "gray" }}
                                  cols="5"
                                  rows="4"
                                ></textarea>
                              </div>
                              <div className="d-flex justify-content-center">
                                <button
                                  style={{ justifyContent: "center" }}
                                  className="btn"
                                >
                                  {t("save")}
                                </button>
                              </div>
                            </div>
                          </form>
                        </Box>
                      </Modal>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="shop-page-meta justify-content-between p-2">
            <div>
              {t("total_Items_1_6_of")} {totalPages}
            </div>
            <div>
              {Array.from({ length: Math.ceil(totalPages / 6) }, (_, index) => (
                <button
                  key={index + 1}
                  style={{
                    width: 35,
                    height: 35,
                    marginInline: 5,
                    border: "1px solid #e1e1e1",
                    borderRadius: "3px",
                    fontSize: "13px",
                    color: currentPage === index + 1 ? "white" : "black",
                    fontWeight: "500",
                    background: currentPage === index + 1 ? "red" : "white",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) =>
                    (e.target.style.background =
                      currentPage === index + 1 ? "red" : "#f0f0f0")
                  }
                  onMouseOut={(e) =>
                    (e.target.style.background =
                      currentPage === index + 1 ? "red" : "white")
                  }
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src="/img/category/animal.png"
            alt=""
            width={"200px"}
            height={"200px"}
          />
          <p style={{ marginTop: "20px" }}>
            {t("servicecomponent_p")}{" "}
            <span style={{ color: "orange", fontWeight: "bold" }}>
              {t("service_categories")}
            </span>{" "}
            {t("menu_and_add_jobs_you_can_do")}
          </p>
        </div>
      )}
    </div>
  );
}

export default PendingServicesForm;
