import React from "react";

const DividerComponent = ({ color }) => {
  const dividerStyle = {
    width: "100%",
    height: "2px",
    backgroundColor: color || "black",
    marginBottom: "10px",
  };

  return <div style={dividerStyle}></div>;
};

export default DividerComponent;
