import React from "react";
import { useTranslation } from "react-i18next";

function Newsletter() {
  const { t } = useTranslation();
  return (
    <div className="newsletter-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="newsletter-wrap">
              <div className="newsletter-content">
                <h2 className="title">{t("newsletter")}</h2>
                <p>
                  <span>*</span> {t("newsletter_email")}
                </p>
              </div>
              <div className="newsletter-form">
                <form>
                  <input type="email" placeholder={t("Enter_Your_Email")} />
                  <button type="submit" className="btn">
                   {t("newsletter_button")}
                  </button>
                </form>
              </div>
              <div className="newsletter-shape">
                <img src="/img/images/newsletter_shape01.png" alt="" />
              </div>
              <div className="newsletter-shape shape-two">
                <img src="/img/images/newsletter_shape02.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Newsletter;
