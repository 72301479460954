import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import DashboardComponent from '../../../components/Ws/Provider/Dashboard'
import { useTranslation } from "react-i18next";

const Dashboard = () => {
    const { t } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("dashboard")} />
            <DashboardComponent />
        </div>
    )
}

export default Dashboard