import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  removeItem,
  addItem,
  decreaseItem,
} from "../../../store/slices/cartSlice";
import BreadCumb from "../../../components/Breadcrumb";

const Cart = () => {
  const { t, i18n } = useTranslation();
  const cartItems = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleRemove = (id) => {
    dispatch(removeItem(id));
  };

  const handleIncrease = (item) => {
    dispatch(addItem(item));
  };

  const handleDecrease = (id) => {
    dispatch(decreaseItem(id));
  };

  return (
    <div>
      <BreadCumb path={t("shopping_cart")} />
      {cartItems.length > 0 ? (
        <div className="container my-5">
          <ul className="list-group">
            {cartItems.map((item) => (
              <li
                key={item._id}
                className="list-group-item d-flex justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center">
                  <img
                    src={ item.images[0] ||"img/product/default.png"}
                    width={60}
                    alt={item.name}
                  />
                  <div className="mx-3">
                    <h5 className="mb-1">{item.name}</h5>
                    <p className="mb-1">{item.price}₺</p>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  {
                    <div className="">
                      {item.quantity > 1 ? (
                        <button
                          className="quantity-btn"
                          onClick={() => handleDecrease(item._id)}
                          style={{
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #ddd",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                        >
                          -
                        </button>
                      ) : (
                        <button
                          onClick={() => handleRemove(item._id)}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                            color: "#ff0000",
                            fontSize: "20px",
                          }}
                        >
                          <i className="far fa-trash-alt" />
                        </button>
                      )}
                      <span
                        className="quantity-number"
                        style={{
                          fontSize: "16px",
                          padding: "0 10px",
                        }}
                      >
                        {item.quantity}
                      </span>
                      <button
                        onClick={() => handleIncrease(item)}
                        style={{
                          backgroundColor: "#f5f5f5",
                          border: "1px solid #ddd",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        +
                      </button>
                    </div>
                  }
                </div>
              </li>
            ))}
          </ul>
          <div className="total-price">
            <span className="f-left">{t("total")}:</span>
            <span className="f-right">
              {cartItems
                .reduce((total, item) => total + item.price * item.quantity, 0)
                .toFixed(2)}
                ₺
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <button
              style={{ width: "max-content" ,justifyContent: "center",}}
              className="btn mt-3"
            >
              {t("pay")}
            </button>
          </div>
        </div>
      ) : (
        <div
          style={{ marginBlock: 100 }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <img
            src="/img/empty-cart.png"
            alt=""
            width={"100"}
            height={"100px"}
          />
          <p style={{ marginTop: "20px" }}>{t("cart_empty")}...</p>
        </div>
      )}
    </div>
  );
};

export default Cart;
