import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SideBarTitle() {
  const { t, i18n } = useTranslation();
  return (
    <div className="rc-post-list">
      <ul>
        <li>
          <div className="rc-post-thumb">
            <Link to="/blog-details">
              <img src="/img/blog/rc_post_thumb01.jpg" alt="" />
            </Link>
          </div>
          <div className="rc-post-content">
            <h5 className="title">
              <Link to="/blog-details">
              {t("your_pet_needs")}
              </Link>
            </h5>
            <div className="rc-post-meta">
              <ul>
                <li>
                  <i className="far fa-calendar-alt" />{t("april_15_2021")}
                </li>
                <li>
                  {t("by")} <a href="/#">{t("admin")}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div className="rc-post-thumb">
            <Link to="/blog-details">
              <img src="/img/blog/rc_post_thumb02.jpg" alt="" />
            </Link>
          </div>
          <div className="rc-post-content">
            <h5 className="title">
              <Link to="/blog-details">
              {t("pets_need_special_food")}
              </Link>
            </h5>
            <div className="rc-post-meta">
              <ul>
                <li>
                  <i className="far fa-calendar-alt" />  {t("october_15_2021")}
                </li>
                <li>
                {t("by")} <a href="/#">{t("admin")}</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default SideBarTitle;
