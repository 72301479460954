import React, { useEffect, useState } from "react";
import OfferList from "../../../components/Ws/User/Offers";
import BreadCumb from "../../../components/Breadcrumb";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Offer = () => {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [values, setValues] = useState();
  const params = useParams();

  useEffect(() => {
    const getOffers = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(`${API_URL}offers/${params.id}`, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        setValues(response.data);
      }
    };
    getOffers();
  }, [params, token]);

  return (
    <div>
      <BreadCumb path={t("Offers")} />
      {values && <OfferList values={values} />}
    </div>
  );
};

export default Offer;
