import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const CategoryItem = ({
  category,
  name,
  depth = 0,
  selectedCategory,
  setSelectedCategory,
  onCategorySelect,
}) => {
  const [open, setOpen] = useState(false);
  const hasChildren =
    (typeof category === "object" && category !== null) ||
    Array.isArray(category);

  // Update open state based on selectedCategory
  useEffect(() => {
    if (selectedCategory.includes(name)) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedCategory, name]);

  const handleClick = () => {
    setOpen(!open);
    setSelectedCategory(name);
    onCategorySelect(name);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        style={{
          paddingLeft: depth * 20,
          backgroundColor: selectedCategory.includes(name)
            ? "#e0e0e0"
            : "inherit",
        }}
      >
        {hasChildren && (
          <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        )}
        <ListItemText primary={name} />
      </ListItem>
      {Array.isArray(category) ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {category.map((item, index) => (
              <ListItem
                button
                key={index}
                style={{
                  paddingLeft: (depth + 1) * 20,
                  backgroundColor: selectedCategory.includes(item)
                    ? "#e0e0e0"
                    : "inherit",
                }}
                onClick={() => {
                  setSelectedCategory(item);
                  onCategorySelect(item);
                }}
              >
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      ) : (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.entries(category).map(([key, value]) => (
              <CategoryItem
                key={key}
                category={value}
                name={key}
                depth={depth + 1}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                onCategorySelect={onCategorySelect}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const EditProductModal = ({
  open,
  onClose,
  token,
  categories,
  setCategory,
  productToEdit,
}) => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(null);

  const resetForm = () => {
    setName("");
    setPrice("");
    setIntroduction("");
    setSelectedCategory([]);
    setUploadedImages([]);
  };

  useEffect(() => {
    if (productToEdit) {
      resetForm();
      setName(productToEdit.name);
      setPrice(productToEdit.price);
      setIntroduction(productToEdit.introduction || "");
      setSelectedCategory(productToEdit.category);
      setUploadedImages(productToEdit.images || []);
    }
  }, [productToEdit]);

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "70%",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    maxHeight: "90%",
  };

  // const handleFileSelect = (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (!selectedFile) return;
  //   console.log("Selected file name:", selectedFile.name);
  //   console.log("File type:", selectedFile.type);
  //   console.log("File size:", selectedFile.size, "bytes");
  // };

  const handleFileSelect = (event) => {
    const a = event.target.files;
    const selectedFile = event.target.files[0];
    console.log(a.length);
    if (a.length > 4) {
      onClose();
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You can only upload up to 4 images.",
      });

      return;
    }

    if (!selectedFile || imageIndex === null) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedImages = [...uploadedImages];
      updatedImages[imageIndex] = reader.result;
      setUploadedImages(updatedImages);
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleEditProduct = async (e) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const payload = {
        price,
        name,
        introduction,
        category: selectedCategory,
        images: uploadedImages,
      };

      const response = await axios.put(
        `${API_URL}products/${productToEdit._id}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );

      if (response.data) {
        onClose();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your product has been added",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      onClose();
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };

  const handleCategorySelect = (category) => {
    let updatedCategories;

    if (selectedCategory.includes(category)) {
      updatedCategories = selectedCategory.filter((cat) => cat !== category);
    } else {
      updatedCategories = [...selectedCategory, category];
    }

    setSelectedCategory(updatedCategories);
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="col-md-5 col-10" sx={boxStyle}>
        <div
          onClick={onClose}
          style={{
            position: "absolute",
            cursor: "pointer",
            top: 5,
            right: 5,
            zIndex: 9999,
          }}
        >
          <img src="/img/close.png" alt="close_button" width={40} height={40} />
        </div>
        <form onSubmit={handleEditProduct} className="contact-form">
          <div className="form-grp d-flex justify-content-center flex-column align-items-center">
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
              }}
            >
              {t("Products_Image")}
            </Typography>
            <div
              className="d-flex justify-content-center align-items-center flex-wrap"
              style={{ gap: "10px" }}
            >
              {uploadedImages.map((image, index) => (
                <label
                  htmlFor="fileInput"
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => setImageIndex(index)}
                >
                  <img
                    src={image}
                    alt=""
                    style={{ width: "100px", height: "100px", margin: "5px" }}
                  />
                </label>
              ))}
            </div>
            {/* <label htmlFor="fileInput">
              <div
                className="d-flex justify-content-center align-items-center mb-4"
                style={{
                  backgroundColor: "#637579",
                  width: "200px",
                  height: "200px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{ color: "white" }}
                  className="fas fa-camera fa-2x"
                ></i>
              </div>
            </label> */}
            <input
              id="fileInput"
              type="file"
              style={{ display: "none" }}
              multiple
              onChange={(e) => handleFileSelect(e)}
            />
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("Products_Name")}
              </Typography>
              <input
                required
                id="product"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("introduction")}
              </Typography>
              <textarea
                type="text"
                style={{ resize: "none" }}
                id="introduction"
                className="form-control"
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
              />
            </div>
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("category")}
              </Typography>
              {categories &&
                categories.map((category, index) => (
                  <CategoryItem
                    key={index}
                    category={Object.values(category)[0]}
                    name={Object.keys(category)[0]}
                    selectedCategory={selectedCategory}
                    setSelectedCategory={setSelectedCategory}
                    onCategorySelect={handleCategorySelect}
                  />
                ))}
            </div>
            <div className="col-md-10 col my-2">
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "black",
                }}
              >
                {t("price")}
              </Typography>
              <input
                required
                id="price"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div style={{ width: "80%" }} className="mt-3">
              <button
                className="btn btn-info btn-lg btn-block justify-content-center"
                type="submit"
              >
                {t("save")}
              </button>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default EditProductModal;
