import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";
import BreadCumb from "../../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
// import $ from 'jquery'

function ProviderProductDetail() {
  // const [nav1, setnav1] = useState(null);
  // const [nav2,setNav2] = useState(null);
  const { t, i18n } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const [showColor, setShowColor] = useState(true);
  const [activeImageIndex , setActiveImageIndex] = useState(0);
  useEffect(() => {
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    const getAdoptDetail = async () => {
      const response = await axios.get(`${API_URL}products/${params.id}`, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (response.data) {
        setValues(response.data.product);
      }
    };
    getAdoptDetail();
  }, [params, token]);

  useEffect(() => {
    if (values?.category?.some((e) => /mama|food|yem/i.test(e))) {
      setShowColor(false);
    }
  }, [values]);

  const handleImage = (index) => {
    setActiveImageIndex(index);
  };

  const handleActive = (e) => {
    e.preventDefault();

    document.querySelectorAll(".shop-details-dimension ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.parentNode.classList = "active";
  };
  const colorActive = (e) => {
    e.preventDefault();

    document.querySelectorAll(".shop-details-color ul li").forEach((el) => {
      el.classList.remove("active");
    });
    e.target.classList += " active";
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  return (
    <div>
      <BreadCumb path={t("Product_Detail")} />
      <section className="shop-details-area pt-110 pb-50">
        {values && (
          <div className="container">
            <div className="shop-details-wrap">
              <div className="row">
                <div className="col-7">
                  <div className="shop-details-img-wrap">
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane show active"
                        id="item-one"
                        role="tabpanel"
                        aria-labelledby="item-one-tab"
                      >
                        <div className="shop-details-img">
                          <img
                            src={
                              values.images[activeImageIndex] ||
                              "/img/product/product_details.jpg"
                            }
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="item-two"
                        role="tabpanel"
                        aria-labelledby="item-two-tab"
                      >
                        <div className="shop-details-img">
                          <img src="/img/product/shop_details02.jpg" alt="" />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="item-three"
                        role="tabpanel"
                        aria-labelledby="item-three-tab"
                      >
                        <div className="shop-details-img">
                          <img src="/img/product/shop_details03.jpg" alt="" />
                        </div>
                      </div>
                      <div
                        className="tab-pane"
                        id="item-four"
                        role="tabpanel"
                        aria-labelledby="item-four-tab"
                      >
                        <div className="shop-details-img">
                          <img src="/img/product/shop_details04.jpg" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shop-details-nav-wrap">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {values.images.map((image, index) =>(
                        <li className="nav-item" role="presentation">
                        <a
                          className="nav-link active"
                          id="item-one-tab"
                          data-toggle="tab"
                          href="/#item-one"
                          role="tab"
                          aria-controls="item-one"
                          aria-selected="true"
                          onClick={() => handleImage(index)}
                        >
                          <img
                            src={
                              image ||
                              "/img/product/product_details_small.jpg"
                            }
                            style={{
                              objectFit: "cover",
                              width: 123,
                              height: 123,
                            }}
                            alt=""
                          />
                        </a>
                      </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-5">
                  <div className="shop-details-content">
                    <span>{values.petshop.name}</span>
                    <h2 className="title">{values.name}</h2>
                    <div className="shop-details-review">
                      <div className="rating">
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                        <i className="fas fa-star" />
                      </div>
                      <span>( {t("01_Review")} )</span>
                    </div>
                    <div className="shop-details-price">
                      <h2 className="price">{values.price}₺</h2>
                      <h5 className="stock-status">- {t("IN_stock")}</h5>
                    </div>
                    <div className="shop-details-dimension">
                      <span>{t("Weight")} :</span>
                      <ul>
                        <li className="active">
                          <a href="/#" onClick={(e) => handleActive(e)}>
                            2 {t("lbs")}
                          </a>
                        </li>
                        <li>
                          <a href="/#" onClick={(e) => handleActive(e)}>
                            4 {t("lbs")}
                          </a>
                        </li>
                        <li>
                          <a href="/#" onClick={(e) => handleActive(e)}>
                            8 {t("lbs")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    {showColor && (
                      <div className="shop-details-color">
                        <span>{t("color")} :</span>
                        <ul>
                          <li
                            className="active"
                            onClick={(e) => colorActive(e)}
                          />
                          <li
                            className="black"
                            onClick={(e) => colorActive(e)}
                          />
                          <li
                            className="green"
                            onClick={(e) => colorActive(e)}
                          />
                          <li
                            className="blue"
                            onClick={(e) => colorActive(e)}
                          />
                        </ul>
                      </div>
                    )}
                    <div className="shop-details-bottom">
                      <ul>
                        <li className="sd-category">
                          <span className="title">{t("categories")} :</span>
                          {values.category.map((category) => (
                            <span
                              key={category}
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#a6a6a6",
                              }}
                            >
                              {category},
                            </span>
                          ))}
                        </li>
                        <li className="sd-sku">
                          <span className="title">SKU :</span>
                          <Link to="/shop">H#21546</Link>
                        </li>
                        <li className="sd-share">
                          <span className="title">{t("share_now")} :</span>
                          <a href="/#">
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a href="/#">
                            <i className="fab fa-twitter" />
                          </a>
                          <a href="/#">
                            <i className="fab fa-linkedin-in" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="product-desc-wrap">
                  <ul className="nav nav-tabs" id="myTabTwo" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="details-tab"
                        data-toggle="tab"
                        href="/#details"
                        role="tab"
                        aria-controls="details"
                        aria-selected="true"
                      >
                        {t("details_more")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="val-tab"
                        data-toggle="tab"
                        href="/#val"
                        role="tab"
                        aria-controls="val"
                        aria-selected="false"
                      >
                        {t("Information")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="review-tab"
                        data-toggle="tab"
                        href="/#review"
                        role="tab"
                        aria-controls="review"
                        aria-selected="false"
                      >
                        {t("Reviews")} (0)
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContentTwo">
                    <div
                      className="tab-pane fade show active"
                      id="details"
                      role="tabpanel"
                      aria-labelledby="details-tab"
                    >
                      <div className="product-desc-content">
                        <p>{values?.introduction}</p>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="val"
                      role="tabpanel"
                      aria-labelledby="val-tab"
                    >
                      <div className="product-desc-info">
                        <div className="row">
                          <div className="col-xl-3 col-md-5">
                            <div className="product-desc-img">
                              <img src="/img/product/desc_img.jpg" alt="" />
                            </div>
                          </div>
                          <div className="col-xl-9 col-md-7">
                            <h5 className="small-title">
                              100% {t("knit_knacks")}
                            </h5>
                            <p>{t("productDetail_p3")}</p>
                            <ul className="product-desc-list">
                              <li>{t("poly_rayon")}</li>
                              <li>{t("partially_lined")}</li>
                              <li>{t("hidden_front_button")}</li>
                              <li>{t("button_cuff_sleeves")}</li>
                              <li>{t("lightweight_semi_sheer_fabrication")}</li>
                              <li>{t("made_in_USA")}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="review"
                      role="tabpanel"
                      aria-labelledby="review-tab"
                    >
                      <div className="product-desc-review">
                        <div className="review-title mb-20">
                          <h4 className="title">{t("customer_reviews")} (0)</h4>
                        </div>
                        <div className="left-rc">
                          <p>{t("no_reviews_yet")}</p>
                        </div>
                        <div className="right-rc">
                          <a href="/#">{t("write_a_review")}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
export default ProviderProductDetail;
