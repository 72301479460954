import React from "react";
import UserComponent from "../../../components/Ws/User/UserComponent";

const User = () => {
  return (
    <div>
      <UserComponent />
    </div>
  );
};

export default User;
