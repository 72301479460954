import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FaCalendarAlt,
  FaCookieBite,
  FaDollarSign,
  FaMapPin,
  FaPhone,
} from "react-icons/fa";
import { Typography } from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

function CardDetailComponent() {
  const { t } = useTranslation();
  const [values, setValues] = useState();
  const params = useParams();
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    const getPetDetail = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const response = await axios.get(`${API_URL}pets/${params.id}`);
      if (response.data) {
        setValues(response.data);
      }
    };
    getPetDetail();
  }, [token, params]);

  const handlePrice = (e) => {
    const inputValue = e.target.value.replace(/[^0-9.,]/g, "");
    e.target.value = inputValue;
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleOffer = async (e) => {
    e.preventDefault();
    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    try {
      const payload = {
        petOfJob: values._id,
        user: values.pet.user,
        comment: e.target.offer_message.value,
        price: e.target.offer_price.value,
        status: 0,
      };
      const res = await axios.post(`${API_URL}offers/`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });
      if (res.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your offer has been successfully sent",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    }
  };

  return (
    <section className="breeder-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {values ? (
              <div className="breeder-details-content">
                <h4 className="title">{values?.fullname}</h4>
                <p>{values?.desc}</p>
                <div className="breeder-details-img">
                  <img src="/img/images/breeder_details.jpg" alt="" />
                </div>
                <div className="breeder-dog-info">
                  <h5 className="title">{t("pet_Information")}</h5>
                  <div className="row">
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("breed")}:</h6>
                        <span>{values.breed}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("color")}:</h6>
                        <span>{values.color}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("size")}:</h6>
                        <span>
                          {values.size} {t("lbs")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("age")}:</h6>
                        <span>
                          {calculateAge(values?.birthday)} {t("years")}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("gender")}:</h6>
                        <span>{values.gender}</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("harmony")}:</h6>
                        <span>21.8</span>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      <div className="breeder-info-item">
                        <h6>{t("available_dates")}:</h6>
                        {Object.entries(values.petJobs?.dates).map(
                          ([day, times]) => {
                            if (times.start_date && times.end_date) {
                              const startDate = new Date(
                                times.start_date
                              ).toLocaleTimeString("tr-TR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              });
                              const endDate = new Date(
                                times.end_date
                              ).toLocaleTimeString("tr-TR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              });
                              return (
                                <span key={day}>
                                  {day}: {startDate} - {endDate}
                                </span>
                              );
                            }
                            return null;
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>{t("loading")}...</div>
            )}
          </div>
          <div className="col-lg-4">
            <aside className="breeder-sidebar">
              <div className="widget breeder-widget">
                <div className="breeder-widget-title mb-20">
                  <h5 className="title">{t("summary")}</h5>
                </div>
                <div className="sidebar-find-pets">
                  <div className="d-flex m-1 flex-row aling-items-center justify-content-start">
                    <FaDollarSign size={18} className="mx-2" />
                    <Typography>{t("looking_at_the_price")}</Typography>
                  </div>
                  <div className="d-flex m-1 flex-row aling-items-center justify-content-start">
                    <FaPhone size={18} className="mx-2" />
                    <Typography>{t("you_can_call_the_customer")}</Typography>
                  </div>
                  <div className="d-flex m-1 flex-row aling-items-center justify-content-start">
                    <FaMapPin size={18} className="mx-2" />
                    <Typography>{t("caferaga_neighborhood")}</Typography>
                  </div>
                  <div className="d-flex m-1 flex-row aling-items-center justify-content-start">
                    <FaCalendarAlt size={18} className="mx-2" />
                    <Typography>
                      {t("tuesday")} (08:00-12:00), {t("friday")} (12:00-18:00)
                    </Typography>
                  </div>
                </div>
              </div>
              <div className="widget breeder-widget">
                <div className="breeder-widget-title mb-20">
                  <h5 className="title">{t("about_of_Joe_Doe")}</h5>
                </div>
                <div className="sidebar-find-pets">
                  <div className="d-flex m-1 flex-row aling-items-center justify-content-start">
                    <FaCalendarAlt size={18} className="mx-2" />
                    <Typography>{t("member_for_years")}</Typography>
                  </div>
                  <div className="d-flex m-1 flex-row aling-items-center justify-content-start">
                    <FaCookieBite size={18} className="mx-2" />
                    <Typography>{t("received_services")}</Typography>
                  </div>
                </div>
              </div>
              {values?.offer ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontFamily: "Nunito",
                    backgroundColor: "transparent",
                    color: "green",
                    borderRadius: "3px",
                    padding: "5px",
                  }}
                >
                  {t("offer_has_been_sent")}
                </div>
              ) : (
                <div className="widget breeder-widget">
                  <div className="breeder-widget-title mb-20">
                    <h5 className="title">{t("send_your_offer")}</h5>
                  </div>
                  <form className="contact-form" onSubmit={handleOffer}>
                    <div className="sidebar-find-pets form-grp">
                      <label htmlFor="offer_message">{t("message")}</label>
                      <textarea
                        name="offer_message"
                        id="offer_message"
                        style={{
                          resize: "none",
                          width: "100%",
                          height: "200px",
                          marginBottom: "10px",
                        }}
                      ></textarea>
                      <label htmlFor="offer_price">{t("price")} (₺)</label>
                      <div className="d-flex  align-items-center">
                        <input
                          id="offer_price"
                          label="Price"
                          variant="standard"
                          onInput={handlePrice}
                        />
                      </div>

                      <button
                        style={{ backgroundColor: "green", marginTop: "20px" }}
                        className="btn"
                      >
                        {t("give_offer")}{" "}
                        <img src="/img/icon/w_pawprint.png" alt="" />
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CardDetailComponent;
