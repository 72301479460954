import React from "react";
import CardDetailComponent from "../../../components/Ws/AdCard/CardDetail";

const CardDetail = () => {
  return (
    <div>
      <CardDetailComponent />
    </div>
  );
};

export default CardDetail;
