import React, { useEffect } from "react";
import "magnific-popup";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Settings({ afterElment }) {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        /* magnificPopup video view */
        $(".popup-video").magnificPopup({
            type: "iframe",
        });
    }, []);

    return (
        <section className={afterElment + " faq-bg"}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="faq-img-wrap">
                            <img src="/img/images/faq_tv.png" className="img-frame" alt="" />
                            <img src="/img/images/faq_img.png" className="main-img" alt="" />
                            <Link
                                to="/https://www.youtube.com/watch?v=XdFfCPK5ycw"
                                className="popup-video"
                            >
                                {" "}
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-wrapper">
                            <div className="section-title mb-35">
                                <h2 className="title">{t("my_services")}</h2>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button
                                                className="btn btn-link btn-block text-left"
                                                type="button"
                                                data-toggle="collapse"
                                                data-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            >
                                                {t("working_adoption_title")}
                                            </button>
                                        </h2>
                                    </div>
                                    <div
                                        id="collapseOne"
                                        className="collapse show"
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample"
                                    >
                                        <div className="card-body">
                                         {t("working_description")}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="faq-shape">
                <img src="/img/images/faq_shape.png" alt="" />
            </div>
        </section>
    );
}

export default Settings;
