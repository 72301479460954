import React from "react";
import { createRoot } from "react-dom/client";
import $ from "jquery";
import "./i18n";

import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement, $("#preloader").delay(0).fadeOut());

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
