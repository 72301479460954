import { HashRouter as Router, Switch, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import Home from "./pages/Home";
import DogList from "./pages/DogList";
import AdoptionsPages from "./pages/AdoptionsPages";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ShopPages from "./pages/ShopPages";
import ShopDetailsPage from "./pages/ShopDetailsPage";
import BreederPage from "./pages/BreederPage";
import BreederDetailsPage from "./pages/BreederDetailsPage";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Provider from "./pages/Ws/Provider/index";
import User from "./pages/Ws/User/index";
import CardDetail from "./pages/Ws/Provider/CardDetail";
import ServiceSettings from "./pages/services/ServiceSettings";
import PersonalInfo from "./pages/services/PersonalInfo";
import About from "./pages/About";
import OurServices from "./pages/OurServices";
import Pets from "./pages/Ws/User/Pets";
import ActiveServices from "./pages/Ws/User/ActiveServices";
import UserSettings from "./pages/Ws/User/UserSettings";
import ServiceHistory from "./pages/Ws/User/ServiceHistory";
import ChangePassword from "./pages/Ws/User/ChangePassword";
import PaymentInfo from "./pages/Ws/User/PaymentInfo";
import ServiceCategories from "./pages/Ws/Provider/ServiceCategories";
import ActiveService from "./pages/Ws/Provider/ActiveService";
import PendingService from "./pages/Ws/Provider/PendingService";
import ProServiceHistory from "./pages/Ws/Provider/ServiceHistory";
import ProSettings from "./pages/Ws/Provider/Settings";
import ProPayment from "./pages/Ws/Provider/ProPayment";
import PasswordChange from "./pages/Ws/Provider/PasswordChange";
import EventDetail from "./pages/Ws/User/EventDetail";
import NewsDetail from "./pages/Ws/User/NewsDetail";
import LostPetDetail from "./components/LostPets/Detail";
import ServiceHistoryDetail from "./components/Ws/User/Services/HistoryDetail";
import AllNews from "./components/News/AllNews";
import AllEvents from "./components/Events/AllEvents";
import GroupDetail from "./pages/Ws/User/GroupDetail";
import AllGroups from "./components/Groups/AllGroups";
import AllAdopts from "./components/Adopt/AllAdopt";
import AdoptDetail from "./pages/Ws/User/AdoptDetail";
import AllLosts from "./components/LostPets/AllLost";
import Services from "./pages/Ws/User/Services";
import ActiveDetail from "./components/Ws/User/Services/ActiveDetail";
import ServiceDetail from "./pages/Ws/Provider/ServiceDetail";
import ServicesDetail from "./pages/Ws/User/ServicesDetail";
import Offer from "./pages/Ws/User/Offer";
import ProviderProfile from "./components/Ws/User/ProviderProfile";
import ProActiveDetail from "./components/Ws/Provider/Services/ActiveDetail";
import PetDetail from "./pages/Ws/User/PetDetail";
import UserProfile from "./components/Ws/Provider/UserProfile";
import HistoryServicesDetail from "./pages/Ws/User/ServiceHistoryDetail";
import ProHistoryServicesDetail from "./components/Ws/Provider/Services/ServiceHistoryDetail";
import MyProducts from "./pages/Ws/Provider/MyProducts";
import { useEffect } from "react";
import Products from "./pages/Ws/User/Products";
import UserProductDetail from "./pages/Ws/User/ProductDetail";
import ProviderProductDetail from "./pages/Ws/Provider/ProductDetail";
import ProductsCMode from "./pages/Ws/ProductsCMode";
import PetshopInformation from "./pages/Ws/Provider/PetshopInformation";
import ProductDetailCMode from "./pages/Ws/ProductDetailCMode";
import Cart from "./pages/Ws/User/Cart";
import Privacy from "./pages/Policy/Privacy";
import Terms from "./pages/Policy/Terms";
import Editorial from "./pages/Policy/Editorial";
import MyOrders from "./pages/Ws/User/MyOrders";

function App() {
  const user = JSON.parse(localStorage.getItem("pet_user"));

  useEffect(() => {
    if (
      user &&
      user?.registerType === "User" &&
      !window.location.hash.includes("app")
    ) {
      window.location.hash = "#/app/user";
    } else if (
      user &&
      user?.registerType === "Provider" &&
      !window.location.hash.includes("app")
    ) {
      window.location.hash = "#/app/pro";
    }
  }, [user]);

  return (
    <div className="app">
      <Router>
        <Header />
        <Switch>
          <ScrollToTopRoute exact={true} path="/">
            <Home />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/about_us">
            <About />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/our_services">
            <OurServices />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/register">
            <Register />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/login">
            <Login />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro/:id">
            {user && user.registerType === "Provider" ? (
              <CardDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro">
            {user && user.registerType === "Provider" ? (
              <Provider />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_service_categories">
            {user && user.registerType === "Provider" ? (
              <ServiceCategories />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_services">
            {user && user.registerType === "Provider" ? (
              <ActiveService />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_services/:id">
            {user && user.registerType === "Provider" ? (
              <ProActiveDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_pending_services">
            {user && user.registerType === "Provider" ? (
              <PendingService />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_pending_services/:id">
            {user && user.registerType === "Provider" ? (
              <ServiceDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_service_history">
            {user && user.registerType === "Provider" ? (
              <ProServiceHistory />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_history_services/:id">
            {user && user.registerType === "Provider" ? (
              <ProHistoryServicesDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/my_products">
            {user && user.registerType === "Provider" ? (
              <MyProducts />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/my_products/:id">
            {user && user.registerType === "Provider" ? (
              <ProviderProductDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/petshop_info">
            {user && user.registerType === "Provider" ? (
              <PetshopInformation />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_settings">
            {user && user.registerType === "Provider" ? (
              <ProSettings />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_payment">
            {user && user.registerType === "Provider" ? (
              <ProPayment />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/pro_change_password">
            {user && user.registerType === "Provider" ? (
              <PasswordChange />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/personal_info">
            {user ? <PersonalInfo /> : <Redirect to={"/login"} />}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/services">
            {user ? <ServiceSettings /> : <Redirect to={"/login"} />}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user">
            {user && user.registerType === "User" ? (
              <User />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_pets">
            {user && user.registerType === "User" ? (
              <Pets />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_pets/:id">
            {user && user.registerType === "User" ? (
              <PetDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_service_active">
            {user && user.registerType === "User" ? (
              <ActiveServices />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_service_active/:id">
            {user && user.registerType === "User" ? (
              <ActiveDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_services">
            {user && user.registerType === "User" ? (
              <Services />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_services/:id">
            {user && user.registerType === "User" ? (
              <ServicesDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_history_services/:id">
            {user && user.registerType === "User" ? (
              <HistoryServicesDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_service_history">
            {user && user.registerType === "User" ? (
              <ServiceHistory />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_service_history/:id">
            {user && user.registerType === "User" ? (
              <ServiceHistoryDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_offers/:id">
            {user && user.registerType === "User" ? (
              <Offer />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user/my_orders">
            {user && user.registerType === "User" ? (
              <MyOrders />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_settings">
            {user && user.registerType === "User" ? (
              <UserSettings />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_change_password">
            {user && user.registerType === "User" ? (
              <ChangePassword />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user_payment">
            {user && user.registerType === "User" ? (
              <PaymentInfo />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/cart">
            {user && user.registerType === "User" ? (
              <Cart />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/products">
            {user && user.registerType === "User" ? (
              <Products />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/products/:id">
            {user && user.registerType === "User" ? (
              <UserProductDetail />
            ) : (
              <Redirect to={"/login"} />
            )}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/provider/:id">
            {user ? <ProviderProfile /> : <Redirect to={"/login"} />}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/user/:id">
            {user ? <UserProfile /> : <Redirect to={"/login"} />}
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/event/:id">
            <EventDetail />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/events">
            <AllEvents />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/groups">
            <AllGroups />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/group/:id">
            <GroupDetail />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/adopt">
            <AllAdopts />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/adopt/:id">
            <AdoptDetail />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/news">
            <AllNews />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/news/:id">
            <NewsDetail />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/lost">
            <AllLosts />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/app/lost/:id">
            <LostPetDetail />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/cart">
            <Cart />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/doglist">
            <DogList />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/adoption">
            <AdoptionsPages />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/contacts">
            <ContactPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/products">
            <ProductsCMode />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/products/:id">
            <ProductDetailCMode />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/blogs">
            <BlogPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/blog-details">
            <BlogDetailsPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/shop">
            <ShopPages />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/shop-details">
            <ShopDetailsPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/breeder">
            <BreederPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/breeder-details">
            <BreederDetailsPage />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/privacy">
            <Privacy />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/terms">
            <Terms />
          </ScrollToTopRoute>
          <ScrollToTopRoute exact={true} path="/editorial">
            <Editorial />
          </ScrollToTopRoute>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
