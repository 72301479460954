import React from "react";
import { useTranslation } from "react-i18next";
import BreadCumb from "../../../components/Breadcrumb";

const MyOrders = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <BreadCumb path={t("my_orders")} />
      <div className="container my-5">
        <ul className="list-group">
          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center">
              <img src={"img/product/default.png"} width={60} alt={""} />
              <div className="mx-3">
                <h5 className="mb-1">{t("dog_food")}</h5>
                <p className="mb-1">$20</p>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <div className="text-end">
                <p className="mb-1">
                  {t("order_date")}:{" "}
                  <span style={{ fontWeight: "bold" }}>2024-08-15</span>
                </p>
                <p className="mb-1">
                  {t("Quantity")}: <span style={{ fontWeight: "bold" }}>2</span>
                </p>
                <p className="mb-1">
                  {t("price")}: <span style={{ fontWeight: "bold" }}>$20</span>
                </p>
                <button className="btn btn-primary btn-block btn-sm btn-md-auto">{t("reorder")}</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MyOrders;
