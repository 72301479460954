import React from 'react'
import BreadCumb from '../../../components/Breadcrumb'
import PaymentInfoForm from '../../../components/Ws/User/Settings/PaymentInfoForm'
import { useTranslation } from "react-i18next";

const PaymentInfo = () => {
    const { t, i18n } = useTranslation();
    return (
        <div>
            <BreadCumb path={t("Payment")} />
            <PaymentInfoForm />
        </div>
    )
}

export default PaymentInfo