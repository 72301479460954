import React, { useEffect } from "react";
import PetsComponent from "../../../components/Ws/User/Pets";
import BreadCumb from "../../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { getPets } from "../../../store/slices/petSlice";
import { getAllBreeds } from "../../../store/slices/breedSlice";
import { useTranslation } from "react-i18next";

const Pets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myPets = useSelector((state) => state.pet.pets);
  const allBreeds = useSelector((state) => state.breed.allBreeds);
  const token = JSON.parse(localStorage.getItem("pet_token"));

  useEffect(() => {
    dispatch(getPets(token));
    dispatch(getAllBreeds());
  }, [dispatch, token]);

  return (
    <div>
      <BreadCumb path={t("MyPets")} />
      <PetsComponent pets={myPets} allBreeds={allBreeds} />
    </div>
  );
};

export default Pets;
