import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

function LostPets() {
  const { t, i18n } = useTranslation();
  const [losts, setLosts] = useState();

  useEffect(() => {
    const getLosts = async () => {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;
      try {
        const res = await axios.get(`${API_URL}lost/1`);
        setLosts(res.data.losts);
      } catch (error) {
        console.log(error);
      }
    };
    getLosts();
  }, []);

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" className={className} onClick={onClick}>
        <img src="/img/icon/arrow.png" alt="prev" />
      </button>
    );
  }
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <button type="button" className={className} onClick={onClick}>
        <img src="/img/icon/arrow.png" alt="next" />
      </button>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          speed: 1000,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          speed: 1000,
        },
      },
    ],
  };

  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const currentDate = new Date();
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("tr-TR").format(date);
    return formattedDate;
  };

  return (
    <section className="adoption-shop-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title text-center mb-65">
              <div className="section-icon">
                <img src="/img/icon/pawprint.png" alt="" />
              </div>
              <h5 className="sub-title">{t("Found_Lost")}</h5>
              <h2 className="title">{t("FL_title")}</h2>
              <p>{t("FL_description")}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="d-flex justify-content-end my-2"
            style={{ cursor: "pointer", color: "darkblue" }}
          >
            <Link to={"/app/lost"} className="btn">
              {t("FL_button")}
              <img src="/img/icon/w_pawprint.png" alt="" />
            </Link>
          </div>
          <Slider className="row adopt-active" {...settings}>
            {losts &&
              losts.map((lost) => (
                <div key={lost._id} className="col-lg-12 col-md-6">
                  <div className="adoption-shop-item">
                    <div className="adoption-shop-thumb">
                      <img
                        src={lost.image_url || "/img/lost_default.png"}
                        alt=""
                      />
                      <Link to={`/app/lost/${lost._id}`} className="btn">
                        {t("detail")}{" "}
                        <img src="/img/icon/w_pawprint.png" alt="" />
                      </Link>
                    </div>
                    <div className="adoption-shop-content">
                      <h4 className="title">
                        <Link to={`/app/lost/${lost._id}`}>
                          {lost.fullname}
                        </Link>
                      </h4>
                      <div className="adoption-meta">
                        <ul>
                          <li>
                            <i className="fas fa-cog" />
                            {lost.breed}
                          </li>
                          <li>
                            <i className="fas fa-birthday-cake"></i>{" "}
                            {calculateAge(lost.birthday)} {t("years")}
                          </li>
                        </ul>
                      </div>
                      <div className="adoption-rating">
                        <ul>
                          <li>Charlottetown</li>
                          <li>
                            <i className="far fa-calendar-alt" />{" "}
                            {`${lost.role}:`} {formatDate(lost.transactionDate)}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default LostPets;
