import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API_URL =
    process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;
const token = JSON.parse(localStorage.getItem("pet_token"));

export const getOffers = createAsyncThunk("offers", async () => {
    try {
        const response = await axios.get(`${API_URL}offers/`, {
            headers: {
                "petapp-auth-token": token,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
});

const initialState = {
    offers: [],
};

const offerSlice = createSlice({
    name: "offers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOffers.fulfilled, (state, action) => {
            state.offers = action.payload;
        });
    },
});

export default offerSlice.reducer;
