import React, { useState } from "react";
import { Modal, Box, Typography, TextField } from "@mui/material";
import DividerComponent from "../../../Divider/Divider";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useTranslation } from "react-i18next";

function OfferList({ values }) {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [cardInfo, setCardInfo] = useState({
    fullname: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const token = JSON.parse(localStorage.getItem("pet_token"));
  const history = useHistory();

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedOffer(null);
    setCardInfo({
      fullname: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    });
  };

  const handleOfferModalForm = async (e) => {
    e.preventDefault();

    // const API_URL =
    //   process.env.REACT_APP_NODE_ENV === "production"
    //     ? process.env.REACT_APP_PRODUCTION_API_URL
    //     : process.env.REACT_APP_LOCAL_API_URL;

    // const response = await axios.post(`${API_URL}offers/payment`, cardInfo, {
    //   headers: {
    //     "petapp-auth-token": token,
    //   },
    // });

    // Kredi kartı bilgilerini doğrulayabilir ve ödemeyi başlatabilirsiniz.
    handleAcceptOffer(e, selectedOffer._id);
  };

  const handleAcceptOffer = async (e, offerId) => {
    e.preventDefault();

    try {
      const API_URL =
        process.env.REACT_APP_NODE_ENV === "production"
          ? process.env.REACT_APP_PRODUCTION_API_URL
          : process.env.REACT_APP_LOCAL_API_URL;

      const currentDate = new Date();
      const startDate = new Date(selectedOffer.service.dates.startDate);

      const status = startDate > currentDate ? 1 : 2;

      const payload = {
        status: status,
        // Kredi kartı bilgilerini burada backend'e gönderebilirsiniz.
        // cardInfo,
      };

      const response = await axios.put(
        `${API_URL}offers/result/${offerId}`,
        payload,
        {
          headers: {
            "petapp-auth-token": token,
          },
        }
      );

      if (response.data) {
        setModalVisible(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "You accepted the offer and payment was successful",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/app/user_service_active");
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Payment Failed",
        text: "There was an issue processing your payment. Please try again.",
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardInfo({
      ...cardInfo,
      [name]: value,
    });
  };

  return (
    <div className="shop-area pt-60 pb-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="shop-wrap">
              <div className="row justify-content-center">
                {values.map((value) => (
                  <div key={value._id} className="col-lg-4 col-sm-6">
                    <div className="shop-item mb-55">
                      <div className="shop-thumb">
                        <Link to={`/app/provider/${value.provider._id}`}>
                          {<img src="/img/unuser.png" alt="" />}
                        </Link>
                      </div>
                      <div className="shop-content">
                        <span>{t(value.service.job)}</span>
                        <h4 className="title">
                          <Link to={`/app/provider/${value.provider._id}`}>
                            {value.provider.fullname}
                          </Link>
                        </h4>
                        <p
                          style={{
                            marginBlock: 10,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {value.comment}
                        </p>
                        <div className="shop-content-bottom">
                          <span>{value.price} ₺</span>
                          <span
                            onClick={() => {
                              setModalVisible(true);
                              setSelectedOffer(value);
                            }}
                            style={{ color: "#f04336", cursor: "pointer" }}
                          >
                            {t("accept")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {selectedOffer && (
                <div>
                  <Modal
                    open={modalVisible}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box className="col-md-6 col-10" sx={boxStyle}>
                      <form
                        className="contact-form"
                        onSubmit={handleOfferModalForm}
                      >
                        <div className="form-grp px-5">
                          <h4 className="title">{t("offer_Information")}</h4>
                          <DividerComponent color={"lightgray"} />
                          <div className="my-3">
                            <Typography style={{ color: "black" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {t("message")}:{" "}
                              </span>{" "}
                              {selectedOffer?.comment}
                            </Typography>
                          </div>
                          <div className="my-3">
                            <Typography style={{ color: "black" }}>
                              <span style={{ fontWeight: "bold" }}>
                                {t("price")}:{" "}
                              </span>
                              {selectedOffer?.price} ₺
                            </Typography>
                          </div>
                          <DividerComponent color={"lightgray"} />
                          <h4 className="title">{t("payment_information")}</h4>
                          <div className="my-3">
                            <TextField
                              label={t("name_surname")}
                              variant="outlined"
                              fullWidth
                              name="fullname"
                              value={cardInfo.fullname}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="my-3">
                            <TextField
                              label={t("card_number")}
                              variant="outlined"
                              fullWidth
                              name="cardNumber"
                              value={cardInfo.cardNumber}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="my-3">
                            <TextField
                              label={t("expiry")}
                              variant="outlined"
                              fullWidth
                              name="expiryDate"
                              value={cardInfo.expiryDate}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <div className="my-3">
                            <TextField
                              label={t("CVV")}
                              variant="outlined"
                              fullWidth
                              name="cvv"
                              value={cardInfo.cvv}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                          <h4 className="title" style={{ textAlign: "center" }}>
                            {t("do_you_accept_the_offer")}
                          </h4>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button
                            onClick={() => {
                              handleCloseModal();
                            }}
                            style={{
                              width: "40%",
                              justifyContent: "center",
                              marginInline: 5,
                            }}
                            className="btn"
                          >
                            {t("cancel")}
                          </button>
                          <button
                            type="submit"
                            style={{
                              width: "40%",
                              justifyContent: "center",
                              marginInline: 5,
                              backgroundColor: "green",
                            }}
                            className="btn"
                          >
                            {t("accept_and_pay")}
                          </button>
                        </div>
                      </form>
                    </Box>
                  </Modal>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfferList;
