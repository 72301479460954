import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const ChangePasswordForm = () => {
  const { t } = useTranslation();
  const token = JSON.parse(localStorage.getItem("pet_token"));

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return passwordRegex.test(password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const API_URL =
      process.env.REACT_APP_NODE_ENV === "production"
        ? process.env.REACT_APP_PRODUCTION_API_URL
        : process.env.REACT_APP_LOCAL_API_URL;

    if (!e.currentTarget.password.value) {
      Swal.fire({
        icon: "error",
        title: "No Password",
        text: "Please enter password.",
      });
      return;
    }

    if (!e.currentTarget.npassword.value) {
      Swal.fire({
        icon: "error",
        title: "No Password",
        text: "Please enter password.",
      });
      return;
    }

    if (!e.currentTarget.cnpassword.value) {
      Swal.fire({
        icon: "error",
        title: "No Confirm Password",
        text: "Please enter confirm password.",
      });
      return;
    }

    if (!validatePassword(e.currentTarget.npassword.value)) {
      Swal.fire({
        icon: "error",
        title: "Invalid Password",
        text: "Password must be at least 6 characters long, contain at least one uppercase letter and one number.",
      });
      return;
    }

    const payload = {
      oldPassword: e.currentTarget.password.value,
      newPassword: e.currentTarget.npassword.value,
      confirmNewPassword: e.currentTarget.cnpassword.value,
    };

    try {
      const res = await axios.post(`${API_URL}users/change_password`, payload, {
        headers: {
          "petapp-auth-token": token,
        },
      });

      if (res.data) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your password has been successfully changed",
        }).then((result) => {
          if (result.isConfirmed) {
            setTimeout(() => {
              window.location.reload();
              window.ReactNativeWebView.postMessage("reload");
            }, 100);
          }
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "An error occurred",
      });
    }
  };
  return (
    <div>
      <div>
        <form
          className="contact-form"
          onSubmit={handleLogin}
          style={{ width: "23rem" }}
        >
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="password">
              {t("current_password")}
            </label>
            <input
              required
              type="password"
              id="password"
              className="form-control"
            />
          </div>
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="password">
              {t("new_password")}
            </label>
            <input
              required
              type="password"
              id="npassword"
              className="form-control"
            />
          </div>
          <div className="form-grp mb-4">
            <label className="form-label" htmlFor="password">
              {t("new_password_confirm")}
            </label>
            <input
              required
              type="password"
              id="cnpassword"
              className="form-control"
            />
          </div>
          <div className="pt-1 mb-4">
            <button
              className="btn btn-info btn-lg btn-block d-flex justify-content-center"
              type="submit"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
